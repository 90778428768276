﻿.overlay {
    /* Height & width depends on how you want to reveal the overlay (see JS below) */
    height: 100%;
    width: 0%;
    position: fixed;
    /* Stay in place */
    z-index: 4;
    /* Sit on top */
    left: 0;
    top: 0;
    background-color: rgb(0, 0, 0);
    /* Black fallback color */
    background-color: rgba(0, 0, 0, 0.9);
    /* Black w/opacity */
    overflow-x: hidden;
    /* Disable horizontal scroll */
    transition: 0.5s;
    /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}


/* Position the content inside the overlay */

.overlay-content {
    position: relative;
    top: 25%;
    /* 25% from the top */
    width: 100%;
    /* 100% width */
    text-align: center;
    /* Centered text/links */
    margin-top: 30px;
    /* 30px top margin to avoid conflict with the close button on smaller screens */
}


/* The navigation links inside the overlay */

.overlay a {
    padding: 0px 8px;
    text-decoration: none;
    color: #818181;
    display: block;
    /* Display block instead of inline */
    transition: 0.3s;
    /* Transition effects on hover (color) */

    @media (min-width: 768px) {
        padding: 0px 5px;
    }
    @media (min-width: 1440px) {
        padding: 0px 8px;
    }
}


/* When you mouse over the navigation links, change their color */

.overlay a:hover,
.overlay a:focus {
    color: #E05054;
}


/* Position the close button (top right corner) */

.overlay .closebtn {
    position: absolute;
    top: 0px;
    right: 20px;
    font-size: 60px;
    z-index: 6;
    color: #eee;
}


/* When the height of the screen is less than 450 pixels, change the font-size of the links and position the close button again, so they don't overlap */

@media screen and (max-height: 450px) {
    .overlay a {
        font-size: 20px
    }
    .overlay .closebtn {
        font-size: 40px;
        top: 15px;
        right: 35px;
    }
}

.welcome-overlay-content {
    position: relative;
    top: 0px;
    width: 100%;
    text-align: center;
    z-index: 5;
}

.woc {
    width: 100%;
    height: 100vh;
    float: left;
    background: url('../img/img_landing_left.jpg') no-repeat left;
    h2 {
        font-size: 5rem;
    }
    p {
        font-size: 2.4rem;
    }
}

.woc-top {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    .woc-logo {
        width: 75%;
        float: left;
    }
    @media (min-width: 768px) {
        .woc-logo {
            float: none;
        }
    }
    @media (min-width: 1024px) {
        .woc-logo {
            width: auto;
        }
    }
}

.woc-right {
    background: url('../img/img_landing_right.jpg') no-repeat right;
}

.woc-left,
.woc-right {
    width: 50%;
    float: left;
    height: 100vh;
    padding: 15% 10% 0 10%;
}

.woc-bottom {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    height: 300px;
    top: 250px;
    @media (min-width: 375px) {
        top: 300px;
    }
    @media (min-width: 768px) {
        top: 400px;
    }
    @media (min-width: 1024px) {
        top: 500px;
    }
}

.woc-btn {
    min-width: 50%;
    color: #fff !important;
    cursor: pointer;
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 45px;
    margin: 0 auto 2em;
    max-width: 400px;
    text-decoration: none;
    text-transform: uppercase;
    border: 1px solid;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    padding: 0px 5px;
    box-shadow: none;

    @media (min-width: 768px) {
        min-width: auto;
        width: 50%;
    }

    @media (min-width: 992px) {
    }

    @media (min-width: 1200px) {
        width: 100%;
        min-width: auto;
    }
    
    span {
        font-size: 1.5rem;
        font-weight: 100;
        line-height: 2em;

        @media (min-width: 768px) {   
            font-size: 1.5em;
            font-weight: 100;
            line-height: 2em;
        }

        @media (min-width: 1200px) {   
            font-size: 1.8em;
            font-weight: 100;
            line-height: 2em;
        }

        @media (min-width: 1440px) {   
            font-size: 2em;
            font-weight: 100;
            line-height: 2em;
        }
    }
}

.woc-btn:hover,.woc-btn:focus,.woc-btn:active {
    background: rgba(255, 255, 255, 0.2);
    box-shadow: none;
}

/********************* Menu Overlay Specific ********************/

.menu-overlay {
    /* Height & width depends on how you want to reveal the overlay (see JS below) */
    height: 100%;
    width: 0;
    position: fixed;
    /* Stay in place */
    z-index: 3;
    /* Sit on top */
    right: 0;
    top: 0;
    /* Black w/opacity */
    overflow-x: hidden;
    /* Disable horizontal scroll */
    transition: 0.5s;
    background: #011a34;
    background: -moz-linear-gradient(-45deg, rgba(1, 26, 52, 0.9)0%, rgba(0, 102, 147, 0.9) 100%);
    background: -webkit-linear-gradient(-45deg, rgba(1, 26, 52, 0.9)0%, rgba(0, 102, 147, 0.9) 100%);
    background: linear-gradient(135deg, rgba(1, 26, 52, 0.9)0%, rgba(0, 102, 147, 0.9) 100%);
}

.menu-overlay a {
    color: #fff;
}

.openMenuBtn {
    z-index: 2;
    position: fixed;
    top: 10px;
    right: 0;
    font-size: 35px;
    width: 54px;
    height: 27px;
    cursor: pointer;
    background-size: cover;

    @media (min-width: 768px) {
        top: 25px;
        right: 35px;
        background: url(../img/heaven.png) no-repeat center center;
        background-size: cover;
    }
}

.menu-content {
    top: 10%;
    width: 50%;
    margin: 0 auto;

    @media (min-width: 768px) { 
        width: 100%;
    }
}

.menu-content a {
    font-size: 2.8rem;
    color: #fff;
    border-bottom: 1px solid rgba(39, 147, 209, 0.8);
    padding: 5px 0px;
    width: 70%;
    margin: 0 auto;
    &:last-child {
        border: none;
    }

    @media (min-width: 768px) {
        font-size: 3em;
        width: 33%;
    }
    @media (min-width: 1024px) {
        font-size: 2em;
        width: 20%;
    }
    @media (min-width: 1440px) {
        font-size: 3em;
    }
}

.menu-footer {
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 0;
    margin-left: 0;
    margin-right: 0;
    height: auto;
    width: 100%;
    padding: 0 5%;
    div {
        width: 100%;
        float: left;
        padding-bottom: 10px;
    }
    @media (min-width: 768px) {
        padding: 0;
        div {
            width: 33.333%;
        }
    }

    @media (min-width: 992px) {
        padding: 0 20%;
    }
    div:last-child {
        display: none;
        @media (min-height: 800px) and (min-width: 320px) {
            display: inline-block;
        }
        @media (min-height: 620px) and (min-width: 768px) {
            display: inline-block;
        }
        a {
            display: inline-block;
        }
    }
    div:nth-child(2) {
        display: none;
        @media (min-height: 680px) and (min-width: 320px) {
            display: inline-block;
        }
        @media (min-height: 620px) and (min-width: 768px) {
            display: inline-block;
        }
        a {
            display: inline-block;
        }
    }
    div:nth-child(1) {
        display: none;
        @media (min-height: 530px) and (min-width: 320px) {
            display: inline-block;
            float: none;
        }
        @media (min-height: 620px) and (min-width: 768px) {
            display: inline-block;
            float: left;
        }
        a {
            display: inline-block;
        }
    }
}


/********************* Slide Overlay Specific **********************/

.slide-overlay {
    /* Height & width depends on how you want to reveal the overlay (see JS below) */
    height: 100%;
    width: 0;
    position: fixed;
    /* Stay in place */
    z-index: 3;
    /* Sit on top */
    right: 0;
    top: 0;
    /* Black w/opacity */
    overflow-x: hidden;
    /* Disable horizontal scroll */
    transition: 0.5s;
    background: #011a34;
    background: -moz-linear-gradient(-45deg, rgba(1, 26, 52, 0.9)0%, rgba(0, 102, 147, 0.9) 100%);
    background: -webkit-linear-gradient(-45deg, rgba(1, 26, 52, 0.9)0%, rgba(0, 102, 147, 0.9) 100%);
    background: linear-gradient(135deg, rgba(1, 26, 52, 0.9)0%, rgba(0, 102, 147, 0.9) 100%);
}

.slide-overlay a {
    color: #fff;
}

.slide-content {
    top: 10%;
    width: 75%;
    margin: 0 auto;
    @media (min-width: 768px) {
        top: 20%;
    }
}

.slide-content a {
    font-size: auto;
    color: #fff;
}

.slide-content-left,
.slide-content-right {
    float: left;
}

.slide-content-left {
    text-align: left;
    width: 100%;
    padding-right: 2%;
    div {
        margin: 0;
    }
    div p {
        font-size: 1.4rem;
        line-height: 1.8;
    }
    img {
        width: 100%;
        margin: 0;
    }
    @media (min-width: 768px) {
        width: 35%;
        h1 {
            font-size: 4.8rem;
        }
    }
}

.slide-content-right {
    width: 100%;
    @media (min-width: 768px) {
        width: 60%;
    }
}

.sc-btn {
    color: #fff !important;
    cursor: pointer;
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 45px;
    margin: 0 auto 2em;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: middle;
    width: 100%;
    border: 1px solid;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
}

.slide-overlay .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
    z-index: 20;
}


/**************** Career View Overlay *************************/

.career-overlay {
    /* Height & width depends on how you want to reveal the overlay (see JS below) */
    height: 0;
    width: 100%;
    position: fixed;
    /* Stay in place */
    z-index: 3;
    /* Sit on top */
    right: 0;
    top: 0;
    /* Black w/opacity */
    overflow-x: hidden;
    /* Disable horizontal scroll */
    transition: 0.5s;
    background: #011a34;
    background: -moz-linear-gradient(-45deg, rgba(1, 26, 52, 0.9)0%, rgba(0, 102, 147, 0.9) 100%);
    background: -webkit-linear-gradient(-45deg, rgba(1, 26, 52, 0.9)0%, rgba(0, 102, 147, 0.9) 100%);
    background: linear-gradient(135deg, rgba(1, 26, 52, 0.9)0%, rgba(0, 102, 147, 0.9) 100%);
    .closebtn {
        position: absolute;
        top: 20px;
        right: 45px;
        font-size: 60px;
        z-index: 3;
        color: #eee;
    }
}

.career-content {
    text-align: left;
    position: relative;
    top: 5%;
    width: 50%;
    margin: 0 auto;
    a {
        color: #eee;
    }
    .headings,
    .TblContent {
        padding: 5px;
    }
    .buttonAlign {
        margin: 0 auto;
    }
    .btnApply {
        color: #fff !important;
        cursor: pointer;
        display: block;
        font-size: 16px;
        font-weight: 400;
        line-height: 45px;
        text-decoration: none;
        text-transform: uppercase;
        border: 1px solid;
        overflow: hidden;
        position: relative;
        border-radius: 10px;
        width: 200px;
        text-align: center;
    }
}

.careers {
    width: 100%;
    display: block;
}


/***************** Map Overlay **************************/

.map-overlay {
    /* Height & width depends on how you want to reveal the overlay (see JS below) */
    height: 0;
    width: 100%;
    position: fixed;
    /* Stay in place */
    z-index: 3;
    /* Sit on top */
    right: 0;
    bottom: 0;
    /* Black w/opacity */
    overflow-x: hidden;
    /* Disable horizontal scroll */
    transition: 0.5s;
    background: #011a34;
    background: -moz-linear-gradient(-45deg, rgba(1, 26, 52, 0.9)0%, rgba(0, 102, 147, 0.9) 100%);
    background: -webkit-linear-gradient(-45deg, rgba(1, 26, 52, 0.9)0%, rgba(0, 102, 147, 0.9) 100%);
    background: linear-gradient(135deg, rgba(1, 26, 52, 0.9)0%, rgba(0, 102, 147, 0.9) 100%);
}

.map-frame {
    width: 100%;
    margin-top: 50%;
    height: 300px;
    @media (min-width: 758px) {
        margin-top: 30%;
        height: 400px;
    }
    @media (min-width: 1024px) {
        margin-top: 25%;
        height: 500px;
    }
}

.map-overlay a {
    color: #fff;
}

.map-content {
    top: 10%;
    width: 75%;
    margin: 0 auto;
    @media (min-width: 768px) {
        top: 20%;
    }
}

.map-content a {
    font-size: auto;
    color: #fff;
}

.map-content-left,
.map-content-right {
    float: left;
}

.map-content-left {
    text-align: left;
    width: 100%;
    padding-right: 2%;
    div {
        margin: 15% 0;
    }
    img {
        width: 100%;
    }
    @media (min-width: 768px) {
        width: 30%;
        h1 {
            font-size: 2.8rem;
        }
    }
}

.map-content-right {
    width: 100%;
    @media (min-width: 768px) {
        width: 65%;
    }
}

.map-overlay .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
    z-index: 3;
}


/***************** Message Overlay **************************/

.message-overlay {
    /* Height & width depends on how you want to reveal the overlay (see JS below) */
    height: 0;
    width: 100%;
    position: fixed;
    /* Stay in place */
    z-index: 3;
    /* Sit on top */
    right: 0;
    bottom: 0;
    /* Black w/opacity */
    overflow-x: hidden;
    /* Disable horizontal scroll */
    transition: 0.5s;
    background: #011a34;
    background: -moz-linear-gradient(-45deg, rgba(1, 26, 52, 0.9)0%, rgba(0, 102, 147, 0.9) 100%);
    background: -webkit-linear-gradient(-45deg, rgba(1, 26, 52, 0.9)0%, rgba(0, 102, 147, 0.9) 100%);
    background: linear-gradient(135deg, rgba(1, 26, 52, 0.9)0%, rgba(0, 102, 147, 0.9) 100%);
}

.message-overlay a {
    color: #fff;
}

.message-content {
    top: 15%;
    width: 75%;
    margin: 0 auto;
    position: relative;
    text-align: left;
    @media (min-width: 768px) {
        top: 20%;
    }
    @media (min-width: 1024px) {
        width: 30%;
    }
    label {
        margin: 0 10px;
    }
    input,
    textarea {
        margin: 10px 0 20px 0;
        height: 40px;
        border-radius: 0;
        padding: 10px;
        width: 100%;
        color: #fff;
        font-size: 2rem
    }
    textarea {
        height: 100px;
    }
    .contact-btn {
        color: #fff !important;
        cursor: pointer;
        display: block;
        font-size: 16px;
        font-weight: 400;
        text-decoration: none;
        text-transform: uppercase;
        border: 1px solid;
        overflow: hidden;
        position: relative;
        border-radius: 10px;
        width: 200px;
        text-align: center;
        background-color: transparent;
        margin: 0 auto;
    }
}

.message-overlay .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
    z-index: 3;
}

.fa {
    font-size: 2rem;
    border-radius: 50%;
    color: rgba(14,84,159, 1);
    background: #fff;
    padding: 10px;
}

.faArrow {    
    font-size: 10rem;
    border-radius: 50%;
    color: #fff;
    background: transparent;
}

.fa:hover, .fa:focus, .fa:active {
    color: #E05054;
    cursor:pointer;
}

.fa-instagram {
    padding-left: 11px;
    padding-right: 11px;
}

.fa-linkedin {  
    padding-left: 11px;
    padding-right: 11px;
}

.fa-twitter {

}

.fa-facebook {
    padding-left: 14px;
    padding-right: 14px;
}

i.fa.fa-bars {
    background: transparent;
    color: rgba(0, 102, 147, 0.9);
    font-size: 3rem;
    padding: 0;
    font-weight: 100;

    @media (min-width: 768px) {
        display: none;
    }
}

