

.pt-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    -webkit-perspective: 1200px;
    -moz-perspective: 1200px;
    perspective: 1200px;
}

.pt-perspective {
    overflow-y: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    -webkit-perspective: 1200px;
    -moz-perspective: 1200px;
    perspective: 1200px;
    overflow: hidden;
}

.pt-page {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    max-height: 100vh;
}

.pt-page-current,
.no-js .pt-page {
    visibility: visible;
    z-index: 1;
}

.no-js body {
    overflow: auto;
}

.pt-page-ontop {
    z-index: 999;
}