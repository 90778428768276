.portfolio-section-left {
    transform: translateY(50%);
}

.portfolio-section {
    height:100%;
}

.our-work-container {
    margin-bottom: 15%;
}

.project-video-showcase {
    background: linear-gradient(135deg, #011a34 0%, #006693 100%);

    iframe {
        height: 360px;

        @media (min-width: 768px) {
            height: 480px;
        }
    }
}

.modal-open .modal {
    display: flex !important;
    align-items: center;
    justify-content: center;
}
.vertical-alignment-helper {
    width: 100%;

    @media (min-width: 768px) {
        width: 50%;
    }
}
.modal-dialog.vertical-align-center {
    width: 100%;
    margin: 0;

    @media (min-width: 768px) {
        margin: 10px;
    }
}
.project-link:hover {
    *{
        color: #E05054;
    }
}

.story-section {
    height: 100%;
}

.modal-header {
    padding: 15px;
    border: 0;
}
.portfolio-logo{
    width: 50%;
    margin-bottom: 30px;

    @media (min-width: 1440px) {
        width: 65%;
    }
}

.portfolio {
    .row {

        @media (min-width: 1024) {
            display: flex !important;
            align-items: center;
            justify-content: center;
        }
    }
}

.content {
    h1 {
        font-size: 1.5em;

        @media (min-width: 768px) {
            font-size: 2em;
        }

        @media (min-width: 1440px) {
            font-size: 4.8rem;
        }
    }

    p {
        font-size: 0.9em;
        display: none;
        @media (min-width: 768px) {
            display: block;
        }

        @media (min-width: 1024px) {

        }

        @media (min-width: 1440px) {
            font-size: 1.8rem;
        }
    }
}

.portfolio-panel {
    h1 {
        font-size: 1.5em;


        @media (min-width: 768px) {
            font-size: 2em;
        }
        @media (min-width: 1440px) {
            font-size: 4.8rem;
        }

    }

    p {
        font-size: 0.8em;
        line-height: 1.1;

        @media (min-width: 768px) {
            font-size: 0.9em;
            line-height: normal;
        }

        @media (min-width: 1440px) {
            font-size: 1.8rem;
        }
    }
}

.panel-previous {
    background-size: cover;
    height: 50%;

    @media (min-width: 768px) {
        height: 100%;
    }
}

.panel-next {
    background-size: cover;
    height: 50%;
    
    @media (min-width: 768px) {
        height: 100%;
    }
}