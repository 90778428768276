﻿/* Generated by less 2.5.1 */


/* DECLARITIVES  */


/******* Preloader ********/

.preload div#preloader {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999999999999;
    width: 100%;
    height: 100%;
    overflow: visible;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loading-gif {
    width: 150px;
    animation: Shrink 2.5s linear infinite;

    
    @keyframes Shrink {
        from {
            width: 150px;
            transform: rotate(0deg);
        }
        50% {
            width: 50px;
            transform: rotate(180deg);
        }
        to {
            width: 150px;
            transform: rotate(360deg);
        }
    }
}


/* FONTS */

@font-face {
    font-family: 'NexaBlack';
    src: url("../fonts/Fontfabric-NexaBlack.eot");
    src: url("../fonts/Fontfabric-NexaBlack.eot?#iefix") format("embedded-opentype"), url("../fonts/Fontfabric-NexaBlack.woff2") format("woff2"), url("../fonts/Fontfabric-NexaBlack.woff") format("woff"), url("../fonts/Fontfabric-NexaBlack.ttf") format("truetype"), url("../fonts/Fontfabric-NexaBlack.svg") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nexa-Bold';
    src: url("../fonts/Fontfabric-Nexa-Bold.eot");
    src: url("../fonts/Fontfabric-Nexa-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Fontfabric-Nexa-Bold.woff2") format("woff2"), url("../fonts/Fontfabric-Nexa-Bold.woff") format("woff"), url("../fonts/Fontfabric-Nexa-Bold.ttf") format("truetype"), url("../fonts/Fontfabric-Nexa-Bold.svg") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'NexaRegular';
    src: url("../fonts/Fontfabric-NexaRegular.eot");
    src: url("../fonts/Fontfabric-NexaRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/Fontfabric-NexaRegular.woff2") format("woff2"), url("../fonts/Fontfabric-NexaRegular.woff") format("woff"), url("../fonts/Fontfabric-NexaRegular.ttf") format("truetype"), url("../fonts/Fontfabric-NexaRegular.svg") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nexa-Regular-Italic';
    src: url("../fonts/Fontfabric-Nexa-Regular-Italic.eot");
    /* IE9 Compat Modes */
    src: url("../fonts/Fontfabric-Nexa-Regular-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/Fontfabric-Nexa-Regular-Italic.woff2") format("woff2"), url("../fonts/Fontfabric-Nexa-Regular-Italic.woff") format("woff"), url("../fonts/Fontfabric-Nexa-Regular-Italic.ttf") format("truetype"), url("../fonts/Fontfabric-Nexa-Regular-Italic.svg") format("svg");
    /* Legacy iOS */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nexa-Light';
    src: url("../fonts/Fontfabric-Nexa-Light.eot");
    src: url("../fonts/Fontfabric-Nexa-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Fontfabric-Nexa-Light.woff2") format("woff2"), url("../fonts/Fontfabric-Nexa-Light.woff") format("woff"), url("../fonts/Fontfabric-Nexa-Light.ttf") format("truetype"), url("../fonts/Fontfabric-Nexa-Light.svg") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nexa-Light-Italic';
    src: url("../fonts/Fontfabric-Nexa-Light-Italic.eot");
    src: url("../fonts/Fontfabric-Nexa-Light-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/Fontfabric-Nexa-Light-Italic.woff2") format("woff2"), url("../fonts/Fontfabric-Nexa-Light-Italic.woff") format("woff"), url("../fonts/Fontfabric-Nexa-Light-Italic.ttf") format("truetype"), url("../fonts/Fontfabric-Nexa-Light-Italic.svg") format("svg");
    font-weight: normal;
    font-style: normal;
}


/* GENERAL STYLES */

*,
*:after,
*:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}


body {
    height: 100%;
    overflow: hidden;
    overflow-x: hidden !important;
    padding: 0; 
    margin: 0;
	max-height: 100vh;
}

html {
    font-size: 62.5%;
    height: 100%;
    padding: 0; 
    margin: 0;
}

body {
    margin: 0;
    color: rgba(2, 23, 37, 0.7);
    background: linear-gradient(135deg, #011a34 0%, #006693 100%);
    font-family: "Nexa-Light", sans-serif;
    line-height: normal;
}

body.overflow-hidden {
    overflow: hidden;
    overflow-y: hidden;
	max-height: 100vh;
}

h1 {
    font-size: 48px;
    font-size: 4.8rem;
    margin-top: 0;
    letter-spacing: .3rem;
    font-weight: 400;
}

h2 {
    font-size: 30px;
    font-size: 3.4rem;
}

h3 {
    font-size: 20px;
    font-size: 3rem;
}

h4 {
    font-size: 16px;
    font-size: 1.6rem;
}

p {
    margin: 0 0 10px;
    font-size: 14px;
    font-size: 1.8rem;
}


/* BUTTONS */

.btn-ghost-blue {
    color: #ffffff;
    border: 1px solid #ffffff;
    background-color: transparent;
    font-family: "Nexa-Light", sans-serif;
    padding: 10px 50px 6px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 18px;
    font-size: 1.8rem;
    margin: 15px;
}

.btn-ghost-blue:hover {
    text-decoration: none;
    border: 1px solid #25aae1;
    color: #ffffff;
    background-color: #25aae1;
    -webkit-transition: all 300ms ease-out 0.3s;
    -moz-transition: all 300ms ease-out 0.3s;
    -o-transition: all 300ms ease-out 0.3s;
    transition: all 300ms ease-out 0.3s;
}

.btn-ghost-red {
    color: #ffffff;
    border: 1px solid #ffffff;
    background-color: transparent;
    font-family: "Nexa-Light", sans-serif;
    padding: 10px 50px 6px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 18px;
    font-size: 1.8rem;
    margin: 15px;
}

.btn-ghost-red:hover {
    border: 1px solid #ffffff;
    text-decoration: none;
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.2);
    -webkit-transition: all 200ms ease-out 0.2s;
    -moz-transition: all 200ms ease-out 0.2s;
    -o-transition: all 200ms ease-out 0.2s;
    transition: all 200ms ease-out 0.2s;
}


/* SIZE ADJUSTS */

.xlarge-text {
    font-size: 62px;
    font-size: 6rem;

    h2 {
        margin-top: 50px;
        font-size: 2.5rem;

        @media (min-width: 360px) {
            margin-top: 20px;
            font-size: 3rem;
        }
        
        @media (min-width: 480px) {
        }
        
        @media (min-width: 768px) {
            font-size: 5rem;
        }
        
        @media (min-width: 992px) {
        }
        
        @media (min-width: 1024px) {
        }
    }

    @media (min-width: 1024px) {
        font-size: 4rem;
    }
    @media (min-width: 1440px) {
        font-size: 6rem;
    }
}

.inner-page-content {
    h2 {
        @media (min-width: 1024px) {
            font-size: 5rem;
            font-weight: 100;
        }

        margin-top: 0;
        margin-bottom: 0;
    }
}

.large {
    font-size: 30px;
    font-size: 4rem;
}

.large-medium {
    font-size: 25px;
    font-size: 3.5rem;
}

.medium {
    font-size: 20px;
    font-size: 3rem;
}

.small {
    font-size: 24px;
    font-size: 2.4rem;
}

.black {
    font-family: "NexaBlack", sans-serif;
}

.bold {
    font-family: "Nexa-Bold", sans-serif;
}

.regular {
    font-family: "NexaRegular", sans-serif;
}

.italic {
    font-family: "Nexa-Regular-Italic", sans-serif;
}

.light {
    font-family: "Nexa-Light", sans-serif;
}

.light-italic {
    font-family: "Nexa-Light-Italic", sans-serif;
}

.need-room {
    padding-top: 0px;

    @media (min-width: 380px) {
        padding: 10px 0;    
    }
    @media (min-width: 1024px) {
        padding: 10px 0;
    }
    @media (min-width: 1440px) {
    }
}

.pt-page {
    .need-room {
        padding-top: 0px;

        @media (min-width: 380px) {
            padding: 80px 0px;
        }
        @media (min-width: 1024px) {
            padding: 40px 0px;
        }
        @media (min-width: 1440px) {
            padding: 80px 0px;
        }
    }
}

.story-section {
    .need-room {
        padding-top: 0px;

        @media (min-width: 380px) {
            padding: 10px 0;
        }
        @media (min-width: 1024px) {
            padding: 10px 0;
        }
        @media (min-width: 1440px) {
            padding: 10px 0;
        }
    }
}

.cloud-block {
    padding: 45px 25%;
    text-align: justify;
    color: #25aae1;
    opacity: 0.5;
}

.active {
    color: #ffffff;
    opacity: 1;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}


/* RUBY */

.home-work-container {
    @media (min-width: 992px) {
        padding: 0 70px;
    }
    @media (min-width: 1200px) {
        padding: 0 70px;
        width: 100%;
    }
    @media (min-width: 1366px) {
        width: 85%;
    }
}

.wow {
    padding: 0px;
}

.grid {
    position: relative;
    margin: 0 auto;
    list-style: none;
    text-align: center;
    height: 100%;
    z-index: 2;
    padding: 0 0;
    width: 100%;

    @media (min-width: 768px) {
        width: 95%;
    }

    @media (min-width: 992px) {
        
    }

    @media (min-width: 1024px) {
        
    }

    @media (min-width: 1440px) {
        
    }

    @media (min-width: 1600px) {
        
    }
}

.pt-page .grid {
    position: relative;
    margin: 0 auto;
    max-width: 1000px;
    list-style: none;
    text-align: center;
    height: 100%;
    z-index: 2;

    @media (min-width: 768px) {
        
    }
}

.grid figure {
    position: relative;
    float: left;
    overflow: hidden;
    max-height: 350px;
    width: 33%;
    background: #3085a3;
    text-align: center;
    cursor: pointer;
    @media (min-width: 425px) {
        width: 44%;
    }
    @media (min-width: 550px) {
        width: 30%;
    }
    @media (min-width: 768px) {    
        width: 32%;
    }
    @media (min-width: 1200px) {
        width: auto;
    }
}

.grid figure .figure-background {
    position: relative;
    display: block;
    min-height: 100%;
    max-width: 100%;
    opacity: 0.8;
    filter: sepia() saturate(200%) hue-rotate(170deg);

    @media (min-width: 768px) {
        filter: sepia() saturate(200%) hue-rotate(170deg);

    }
}

.grid figure figcaption {
    padding: 1rem;
    color: #fff;
    font-size: 1.2rem;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.grid figure figcaption::before,
.grid figure figcaption::after {
    pointer-events: none;
}

.grid figure figcaption,
.grid figure figcaption>a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.grid figure figcaption>a {
    z-index: 1000;
    text-indent: 200%;
    white-space: nowrap;
    font-size: 0;
    opacity: 0;
}

.grid figure h2 {
    word-spacing: -0.15rem;
    font-weight: 300;
}

.grid figure h2 span {
    font-weight: 800;
}

.grid figure h2,
.grid figure p {
    margin: 0;
}

.grid figure p {
    letter-spacing: 1px;
}

figure.effect-ruby h2 {
    margin-top: 20%;
    -webkit-transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
}

figure.effect-ruby h2 img {
    margin: 0 auto;
    margin: -15% auto;
}

figure.effect-ruby h3 {
    font-size: 20px;
    font-size: 1em;
    margin: .5em 0 0;
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: translate3d(0, 20px, 0) scale(1.1);
    transform: translate3d(0, 20px, 0) scale(1.1);

    @media (min-width: 768px) {
        font-size: 2rem;
    }
}

figure.effect-ruby p {
    font-size: 12px;
    font-size: 1.2rem;
    margin: 1em 0 0;
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: translate3d(0, 20px, 0) scale(1.1);
    transform: translate3d(0, 20px, 0) scale(1.1);
}

figure.effect-ruby img {
    opacity: 1;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s ease-in;
    transition: opacity 0.35s, transform 0.35s ease-in;
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
}

figure.effect-ruby:hover h2 {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    margin-top: 0%;
    transition: 0.3s ease-in;
}

figure.effect-ruby:hover h3 {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
}

figure.effect-ruby:hover p {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
}

figure.effect-ruby:hover img {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    filter: grayscale(0);
}

.adjust-logo {
    width: 80%;
    height: auto;
}


/* CAROUSAL */

#carousel-example-generic {
    margin-bottom: -45px;
}

.carousel-indicators {
    bottom: 0px;
}

.carousel-indicators li {
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 10px;
    cursor: pointer;
    display: inline-block;
    height: 10px;
    margin: 1px;
    text-indent: -999px;
    width: 10px;
    padding: 5px;
}

.carousel-indicators .active {
    background-color: #ec2028;
    height: 12px;
    margin: 0;
    width: 12px;
}


/* VIDEO PLAYBACK */

video {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    background: url("../img/video_bg.jpg");
    background-size: cover;
    -webkit-transition: 1s opacity;
    transition: 1s opacity;
}

.stopfade {
    opacity: .5;
}

#polina {
    font-weight: 100;
    background: rgba(0, 0, 0, 0.3);
    color: white;
    width: 100%;
    font-size: 1.2rem;
    overflow: hidden;
}

#polina button {
    display: block;
    width: 80%;
    padding: .4rem;
    border: none;
    margin: 1rem auto;
    font-size: 1.3rem;
    background: rgba(255, 255, 255, 0.23);
    color: #fff;
    border-radius: 3px;
    cursor: pointer;
    -webkit-transition: .3s background;
    transition: .3s background;
}

#polina button:hover {
    background: rgba(0, 0, 0, 0.5);
}


/* HEADER */

.cd-header {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(2, 23, 37, 0.95);
    height: 50px;
    width: 100%;
    z-index: 3;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.cd-logo {
    display: block;
    position: absolute;
    top: 40%;
    bottom: auto;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    left: .875em;
}

.cd-logo img {
    display: block;
    width: 50%;
}


/* BUTTON AND EFFECT */

.cd-primary-nav-trigger {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 50px;
    background-color: #0e549f;
}

.cd-primary-nav-trigger.cd-menu-text {
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    /* hide the text on small devices */
    display: none;
}

.cd-primary-nav-trigger .cd-menu-icon {
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    width: 18px;
    height: 2px;
    background-color: white;
    -webkit-transition: background-color 0.3s;
    -moz-transition: background-color 0.3s;
    transition: background-color 0.3s;
}

.cd-primary-nav-trigger .cd-menu-icon::before,
.cd-primary-nav-trigger .cd-menu-icon:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: white;
    right: 0;
    -webkit-transition: -webkit-transform 0.3s, top 0.3s, background-color 0s;
    -moz-transition: -moz-transform 0.3s, top 0.3s, background-color 0s;
    transition: transform 0.3s, top 0.3s, background-color 0s;
}

.cd-primary-nav-trigger .cd-menu-icon::before {
    top: -5px;
}

.cd-primary-nav-trigger .cd-menu-icon::after {
    top: 5px;
}

.cd-primary-nav-trigger .cd-menu-icon.is-clicked {
    background-color: rgba(255, 255, 255, 0);
}

.cd-primary-nav-trigger .cd-menu-icon.is-clicked::before,
.cd-primary-nav-trigger .cd-menu-icon.is-clicked::after {
    background-color: white;
}

.cd-primary-nav-trigger .cd-menu-icon.is-clicked::before {
    top: 0;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}

.cd-primary-nav-trigger .cd-menu-icon.is-clicked::after {
    top: 0;
    -webkit-transform: rotate(225deg);
    -moz-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    -o-transform: rotate(225deg);
    transform: rotate(225deg);
}


/* CONTAINER */

.cd-container {
    width: 90%;
    max-width: 768px;
    margin: 0 auto;
}

.cd-container:after {
    content: "";
    display: table;
    clear: both;
}


/* NAVIGATION*/

.cd-primary-nav {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(2, 23, 37, 0.9);
    z-index: 2;
    text-align: center;
    padding: 50px 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    transition-property: transform;
    -webkit-transition-duration: 0.4s;
    -moz-transition-duration: 0.4s;
    transition-duration: 0.4s;
}

.cd-primary-nav li {
    font-size: 18px;
    font-size: 1.8rem;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: .2em 0;
    text-transform: capitalize;
}

.cd-primary-nav a {
    display: inline-block;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
    color: #ffffff;
}

.cd-primary-nav.is-visible {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
}

.no-touch .cd-primary-nav a:hover {
    color: #ec2028;
}

.cd-primary-nav .menu-footer {
    padding-top: 20px;
}

.cd-primary-nav .cd-social {
    display: inline-flex;
    margin: 0 .4em;
}

.cd-primary-nav .cd-social a {
    font-size: 20px;
    font-size: 2rem;
    padding-right: 10px;
    color: #ffffff;
}

.cd-primary-nav .cd-social a:hover {
    color: #ec2028;
}

.cd-intro {
    position: relative;
    height: 100%;
    background-size: cover;
    z-index: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.cd-intro h1 {
    position: absolute;
    width: 90%;
    max-width: 1170px;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    color: white;
    font-size: 20px;
    font-size: 2rem;
    font-weight: 300;
    text-align: center;
}

.cd-good {
    display: inline-flex;
    text-align: center;
}

.cd-good li {
    padding: 25px;
}

.cd-good img {
    margin: 0 auto;
    text-align: center;
    border-radius: 500px;
    width: 70%;
    padding: 10px;
}

.cd-good a {
    font-size: 20px;
    font-size: 2rem;
    padding-right: 10px;
    color: #ffffff;
}

.cd-good a:hover {
    color: #ec2028;
}


/* Ripple Out */

@-webkit-keyframes ripple-out {
    100% {
        top: -12px;
        right: -12px;
        bottom: -12px;
        left: -12px;
        opacity: 0;
    }
}

@keyframes ripple-out {
    100% {
        top: -12px;
        right: -12px;
        bottom: -12px;
        left: -12px;
        opacity: 0;
    }
}

.ripple-out {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    box-shadow: 0 0 1px transparent;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
}

.ripple-out:before {
    content: '';
    position: absolute;
    border: 1px solid #ffffff;
    top: 0;
    right: 56px;
    bottom: 0;
    left: 46px;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    border-radius: 500px;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}

.ripple-out:hover:before,
.ripple-out:focus:before,
.ripple-out:active:before {
    -webkit-animation-name: ripple-out;
    animation-name: ripple-out;
}

.client-item {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    img {
        width: 100%;
    }
}

.client-item:hover:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background: rgba(255, 255, 255, 0.1);
    background: none;
}

.client-item .active {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.1);
}

.border-container {
    -webkit-border-image: -webkit-linear-gradient(top, #cccccc 0%, #bbbbbb 50%, #cccccc 100%) 1 stretch;
    border-image: linear-gradient(to bottom, #cccccc 0%, #bbbbbb 50%, #cccccc 100%) 1 stretch;
    border-style: solid;
    border-width: 0 2px 0 0;
}

.footer-line {
    text-align: center;
    border-top: 1px solid #ffffff;
}

.footer-nav {
    display: inline-flex;
}

.footer-nav li {
    margin: 20px 10px;
}

.footer-nav a {
    color: #ffffff;
    text-decoration: none;
}

.footer-nav a:hover {
    color: #ec2028;
    -webkit-transition: all 200ms ease-out 0.2s;
    -moz-transition: all 200ms ease-out 0.2s;
    -o-transition: all 200ms ease-out 0.2s;
    transition: all 200ms ease-out 0.2s;
}


/* MEDIA QUERIES */

@media only screen and (max-width: 480px) {
    h1 {
        font-size: 24px;
        font-size: 2.4rem;
        margin-top: 0;
        letter-spacing: .3rem;
    }
    h2 {
        font-size: 2rem;
        font-size: 20px;
    }
    h3 {
        font-size: 1.6rem;
        font-size: 16px;
    }
    h4 {
        font-size: 16px;
        font-size: 1.6rem;
    }
    p {
        margin: 0 0 10px;
        font-size: 12px;
        font-size: 1.5rem;
    }
    .xlarge-text {
        font-size: 24px;
        font-size: 2.4rem;
    }
    .large {
        font-size: 20px;
        font-size: 2rem;
    }
    .large-medium {
        font-size: 18px;
        font-size: 1.8rem;
    }
    .medium {
        font-size: 16px;
        font-size: 1.6rem;
    }
    .small {
        font-size: 14px;
        font-size: 1.4rem;
    }
    section {
        min-height: 400px;
    }
    footer {
        display: none;
    }
}

@media only screen and (min-width: 768px) {
    .cd-header {
        height: 100px;
        background: transparent;
        box-shadow: none;
    }
    .cd-primary-nav-trigger {
        padding-left: 1em;
        background-color: transparent;
        height: 30px;
        line-height: 30px;
        right: 2.2em;
        top: 40%;
        bottom: auto;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    .cd-primary-nav-trigger .cd-menu-text {
        display: inline-block;
    }
    .cd-primary-nav-trigger .cd-menu-icon {
        left: auto;
        right: 1em;
        -webkit-transform: translateX(0) translateY(-50%);
        -moz-transform: translateX(0) translateY(-50%);
        -ms-transform: translateX(0) translateY(-50%);
        -o-transform: translateX(0) translateY(-50%);
        transform: translateX(0) translateY(-50%);
    }
    .cd-primary-nav {
        padding: 100px 0;
    }
    .cd-intro h1 {
        font-size: 30px;
        font-size: 3rem;
    }
}

@media screen and (max-device-width: 800px) {
    html {
        // background: url(../img/video_bg.jpg) #000000 no-repeat center center fixed;
    }
    #bgvid {
        display: none;
    }
}

@media only screen and (min-width: 1170px) {
    .cd-header {
        -webkit-transition: background-color 0.3s;
        -moz-transition: background-color 0.3s;
        transition: background-color 0.3s;
        /* Force Hardware Acceleration in WebKit */
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }
    .cd-header.is-fixed {
        /* when the user scrolls down, we hide the header right above the viewport */
        position: fixed;
        top: -100px;
        background-image: url(../img/header_overlay.png);
        background-repeat: repeat-x;
        -webkit-transition: -webkit-transform 0.3s;
        -moz-transition: -moz-transform 0.3s;
        transition: transform 0.3s;
    }
    .cd-header.is-fixed>.cd-logo img {
        width: 40%;
    }
    .cd-header.is-visible {
        /* if the user changes the scrolling direction, we show the header */
        -webkit-transform: translate3d(0, 100%, 0);
        -moz-transform: translate3d(0, 100%, 0);
        -ms-transform: translate3d(0, 100%, 0);
        -o-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    .cd-header.menu-is-open {
        /* add a background color to the header when the navigation is open */
        background-color: rgba(2, 23, 37, 0.96);
    }
    .cd-primary-nav li {
        font-size: 30px;
        font-size: 3.0rem;
    }
    .cd-primary-nav .cd-label {
        font-size: 16px;
        font-size: 1rem;
    }
    .cd-intro {
        height: 700px;
        margin-top: 45px;
    }
    section {
        min-height: 600px;
    }
}


/* MISCELLANIOUS STYING BECAUSE I GIVE A SHIT ABOUT MY JOB WHICH NO ONE SEES...LET ALONE APPRECIATES*/

::selection {
    background: #25aae1;
}

::-moz-selection {
    background: #25aae1;
}

.cd-top {
    display: inline-block;
    height: 20px;
    width: 20px;
    position: fixed;
    bottom: 40px;
    right: 10px;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    background: url(../img/back-to-top.svg) no-repeat center 50%;
    visibility: hidden;
    opacity: 0;
    border: 1px solid #ffffff;
    -webkit-transition: opacity .3s 0s, visibility 0s .3s;
    -moz-transition: opacity .3s 0s, visibility 0s .3s;
    transition: opacity .3s 0s, visibility 0s .3s;
}

.cd-top.cd-is-visible,
.cd-top.cd-fade-out,
.no-touch .cd-top:hover {
    -webkit-transition: opacity .3s 0s, visibility 0s 0s;
    -moz-transition: opacity .3s 0s, visibility 0s 0s;
    transition: opacity .3s 0s, visibility 0s 0s;
}

.cd-top.cd-is-visible {
    visibility: visible;
    opacity: 1;
}

.cd-top.cd-fade-out {
    opacity: .5;
}

.no-touch .cd-top:hover {
    background-color: rgba(255, 255, 255, 0.2);
    opacity: 1;
}

@media only screen and (min-width: 768px) {
    .cd-top {
        right: 20px;
        bottom: 20px;
    }
}

@media only screen and (min-width: 1024px) {
    .cd-top {
        height: 30px;
        width: 30px;
        right: 30px;
        bottom: 30px;
        z-index: 2;
    }
}

.cd-image-replace {
    /* replace text with image */
    display: inline-block;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    color: transparent;
}

@media only screen and (min-width: 800px) {
    .cd-nav-trigger {
        display: none;
    }
}

.cd-vertical-nav {
    position: fixed;
    z-index: 1;
    right: 5%;
    bottom: 30px;
    width: 90%;
    max-width: 400px;
    max-height: 90%;
    overflow-y: auto;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transition: -webkit-transform 0.2s;
    -moz-transition: -moz-transform 0.2s;
    transition: transform 0.2s;
    border-radius: 0.25em;
    background-color: rgba(234, 242, 227, 0.9);
}

.cd-vertical-nav a {
    display: block;
    padding: 1em;
    color: #3a2c41;
    font-weight: bold;
    border-bottom: 1px solid rgba(58, 44, 65, 0.1);
}

.cd-vertical-nav a.active, .cd-vertical-nav a:active, .cd-vertical-nav a:hover, .cd-vertical-nav a:focus {
    color: #E05054;
}

.cd-vertical-nav.open {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-overflow-scrolling: touch;
}

.cd-vertical-nav.open+.cd-nav-trigger {
    background-color: transparent;
}

.cd-vertical-nav.open+.cd-nav-trigger span {
    background-color: rgba(58, 44, 65, 0);
}

.cd-vertical-nav.open+.cd-nav-trigger span::before,
.cd-vertical-nav.open+.cd-nav-trigger span::after {
    /* transform dots in 'X' icon */
    height: 3px;
    width: 20px;
    border-radius: 0;
    left: -8px;
}

.cd-vertical-nav.open+.cd-nav-trigger span::before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 1px;
}

.cd-vertical-nav.open+.cd-nav-trigger span::after {
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
    bottom: 0;
}

@media only screen and (min-width: 800px) {
    .cd-vertical-nav {
        /* reset style */
        right: 15px;
        top: 20%;
        bottom: auto;
        height: 100vh;
        width: 90px;
        max-width: none;
        max-height: none;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
        background-color: transparent;
        /* vertically align its content*/
        display: table;
    }
    .cd-vertical-nav::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        -o-transform: translateX(100%);
        transform: translateX(100%);
        -webkit-transition: -webkit-transform 0.4s;
        -moz-transition: -moz-transform 0.4s;
        transition: transform 0.4s;
    }
    .no-touch .cd-vertical-nav:hover::before,
    .touch .cd-vertical-nav::before {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
    .cd-vertical-nav ul {
        /* vertically align <ul> inside <nav>*/
        display: table-cell;
        vertical-align: middle;
        text-align: center;
    }
    .cd-vertical-nav a {
        position: relative;
        padding: 3em 0 0;
        margin: 1.4em auto;
        border-bottom: none;
        font-size: 1.2rem;
        color: #eaf2e3;
    }
    .cd-vertical-nav a.active {
        color: #eaf2e3;
    }
    .cd-vertical-nav a::before,
    .cd-vertical-nav a::after {
        content: '';
        position: absolute;
        left: 50%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform: translateZ(0);
        -moz-transform: translateZ(0);
        -ms-transform: translateZ(0);
        -o-transform: translateZ(0);
        transform: translateZ(0);
    }
    .cd-vertical-nav a::before {
        /* filled circle */
        top: 0;
        height: 35px;
        width: 35px;
        border-radius: 50%;
        border: 1px solid #ffffff;
        -webkit-transform: translateX(-50%) scale(0.25);
        -moz-transform: translateX(-50%) scale(0.25);
        -ms-transform: translateX(-50%) scale(0.25);
        -o-transform: translateX(-50%) scale(0.25);
        transform: translateX(-50%) scale(0.25);
        -webkit-transition: -webkit-transform 0.4s, background-color 0.2s;
        -moz-transition: -moz-transform 0.4s, background-color 0.2s;
        transition: transform 0.4s, background-color 0.2s;
        padding: 15px;
    }
    .cd-vertical-nav a::after {
        opacity: 0;
        top: 0px;
        height: 32px;
        width: 32px;
        -webkit-transform: translateX(-50%) scale(0);
        -moz-transform: translateX(-50%) scale(0);
        -ms-transform: translateX(-50%) scale(0);
        -o-transform: translateX(-50%) scale(0);
        transform: translateX(-50%) scale(0);
        -webkit-transition: -webkit-transform 0.4s 0s, opacity 0.1s 0.3s;
        -moz-transition: -moz-transform 0.4s 0s, opacity 0.1s 0.3s;
        transition: transform 0.4s 0s, opacity 0.1s 0.3s;
    }
    // .cd-vertical-nav a.active::before,
    // .no-touch .cd-vertical-nav a:hover::before {
    //     background-color: #ffffff;
    // }
    .cd-vertical-nav li:first-of-type a::after {
        background-image: url(../img/scroller/scroller_home.png);
        background-size: cover;
        /* fix bug with transitions - set scale(0.01) rather than scale(0) */
        -webkit-transform: translateX(-50%) scale(0.01);
        -moz-transform: translateX(-50%) scale(0.01);
        -ms-transform: translateX(-50%) scale(0.01);
        -o-transform: translateX(-50%) scale(0.01);
        transform: translateX(-50%) scale(0.01);
    }
    .cd-vertical-nav li:first-of-type a::before {
        -webkit-transform: translateX(-50%) scale(0.25);
        -moz-transform: translateX(-50%) scale(0.25);
        -ms-transform: translateX(-50%) scale(0.25);
        -o-transform: translateX(-50%) scale(0.25);
        transform: translateX(-50%) scale(0.25);
    }
    .cd-vertical-nav li:nth-of-type(2) a::after {
        background-image: url(../img/scroller/scroller_about.png);
        background-size: cover;
        background-position: 0px -32px;
        -webkit-transform: translateX(-50%) scale(0.01);
        -moz-transform: translateX(-50%) scale(0.01);
        -ms-transform: translateX(-50%) scale(0.01);
        -o-transform: translateX(-50%) scale(0.01);
        transform: translateX(-50%) scale(0.01);
    }
    .cd-vertical-nav li:nth-of-type(2) a::before {
        -webkit-transform: translateX(-50%) scale(0.25);
        -moz-transform: translateX(-50%) scale(0.25);
        -ms-transform: translateX(-50%) scale(0.25);
        -o-transform: translateX(-50%) scale(0.25);
        transform: translateX(-50%) scale(0.25);
    }
    .cd-vertical-nav li:nth-of-type(3) a::after {
        background-image: url(../img/scroller/scroller_work.png);
        background-size: cover;
        -webkit-transform: translateX(-50%) scale(0.01);
        -moz-transform: translateX(-50%) scale(0.01);
        -ms-transform: translateX(-50%) scale(0.01);
        -o-transform: translateX(-50%) scale(0.01);
        transform: translateX(-50%) scale(0.01);
    }
    .cd-vertical-nav li:nth-of-type(3) a::before {
        -webkit-transform: translateX(-50%) scale(0.25);
        -moz-transform: translateX(-50%) scale(0.25);
        -ms-transform: translateX(-50%) scale(0.25);
        -o-transform: translateX(-50%) scale(0.25);
        transform: translateX(-50%) scale(0.25);
    }
    .cd-vertical-nav li:nth-of-type(4) a::after {
        background-image: url(../img/scroller/scroller_clients.png);
        background-size: cover;
        -webkit-transform: translateX(-50%) scale(0.01);
        -moz-transform: translateX(-50%) scale(0.01);
        -ms-transform: translateX(-50%) scale(0.01);
        -o-transform: translateX(-50%) scale(0.01);
        transform: translateX(-50%) scale(0.01);
    }
    .cd-vertical-nav li:nth-of-type(4) a::before {
        -webkit-transform: translateX(-50%) scale(0.25);
        -moz-transform: translateX(-50%) scale(0.25);
        -ms-transform: translateX(-50%) scale(0.25);
        -o-transform: translateX(-50%) scale(0.25);
        transform: translateX(-50%) scale(0.25);
    }
    .cd-vertical-nav li:nth-of-type(5) a::after {
        background-image: url(../img/scroller/scroller_services.png);
        background-size: cover;
        -webkit-transform: translateX(-50%) scale(0.01);
        -moz-transform: translateX(-50%) scale(0.01);
        -ms-transform: translateX(-50%) scale(0.01);
        -o-transform: translateX(-50%) scale(0.01);
        transform: translateX(-50%) scale(0.01);
    }
    .cd-vertical-nav li:nth-of-type(5) a::before {
        -webkit-transform: translateX(-50%) scale(0.25);
        -moz-transform: translateX(-50%) scale(0.25);
        -ms-transform: translateX(-50%) scale(0.25);
        -o-transform: translateX(-50%) scale(0.25);
        transform: translateX(-50%) scale(0.25);
    }
    .cd-vertical-nav li:nth-of-type(6) a::after {
        background-image: url(../img/scroller/scroller_solutions.png);
        background-size: cover;
        -webkit-transform: translateX(-50%) scale(0.01);
        -moz-transform: translateX(-50%) scale(0.01);
        -ms-transform: translateX(-50%) scale(0.01);
        -o-transform: translateX(-50%) scale(0.01);
        transform: translateX(-50%) scale(0.01);
    }
    .cd-vertical-nav li:nth-of-type(6) a::before {
        -webkit-transform: translateX(-50%) scale(0.25);
        -moz-transform: translateX(-50%) scale(0.25);
        -ms-transform: translateX(-50%) scale(0.25);
        -o-transform: translateX(-50%) scale(0.25);
        transform: translateX(-50%) scale(0.25);
    }
    .cd-vertical-nav li:nth-of-type(7) a::after {
        background-image: url(../img/scroller/scroller_FWL.png);
        background-size: cover;
        -webkit-transform: translateX(-50%) scale(0.01);
        -moz-transform: translateX(-50%) scale(0.01);
        -ms-transform: translateX(-50%) scale(0.01);
        -o-transform: translateX(-50%) scale(0.01);
        transform: translateX(-50%) scale(0.01);
    }
    .cd-vertical-nav li:nth-of-type(7) a::before {
        -webkit-transform: translateX(-50%) scale(0.25);
        -moz-transform: translateX(-50%) scale(0.25);
        -ms-transform: translateX(-50%) scale(0.25);
        -o-transform: translateX(-50%) scale(0.25);
        transform: translateX(-50%) scale(0.25);
    }
    .cd-vertical-nav li:nth-of-type(8) a::after {
        background-image: url(../img/scroller/scroller_careers.png);
        background-size: cover;
        -webkit-transform: translateX(-50%) scale(0.01);
        -moz-transform: translateX(-50%) scale(0.01);
        -ms-transform: translateX(-50%) scale(0.01);
        -o-transform: translateX(-50%) scale(0.01);
        transform: translateX(-50%) scale(0.01);
    }
    .cd-vertical-nav li:nth-of-type(8) a::before {
        -webkit-transform: translateX(-50%) scale(0.25);
        -moz-transform: translateX(-50%) scale(0.25);
        -ms-transform: translateX(-50%) scale(0.25);
        -o-transform: translateX(-50%) scale(0.25);
        transform: translateX(-50%) scale(0.25);
    }
    .cd-vertical-nav li:nth-of-type(9) a::after {
        background-image: url(../img/scroller/scroller_contact.png);
        background-size: cover;
        -webkit-transform: translateX(-50%) scale(0.01);
        -moz-transform: translateX(-50%) scale(0.01);
        -ms-transform: translateX(-50%) scale(0.01);
        -o-transform: translateX(-50%) scale(0.01);
        transform: translateX(-50%) scale(0.01);
    }
    .cd-vertical-nav li:nth-of-type(9) a::before {
        -webkit-transform: translateX(-50%) scale(0.25);
        -moz-transform: translateX(-50%) scale(0.25);
        -ms-transform: translateX(-50%) scale(0.25);
        -o-transform: translateX(-50%) scale(0.25);
        transform: translateX(-50%) scale(0.25);
    }
    .cd-vertical-nav .label {
        display: block;
        /* Force Hardware Acceleration */
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform: translateZ(0);
        -moz-transform: translateZ(0);
        -ms-transform: translateZ(0);
        -o-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        -o-transform: translateX(100%);
        transform: translateX(100%);
        -webkit-transition: -webkit-transform 0.4s;
        -moz-transition: -moz-transform 0.4s;
        transition: transform 0.4s;
    }
    .no-touch .cd-vertical-nav:hover a::before,
    .no-touch .cd-vertical-nav:hover a::after,
    .touch .cd-vertical-nav li:nth-of-type(n) a::before,
    .touch .cd-vertical-nav li:nth-of-type(n) a::after {
        -webkit-transform: translateX(-50%) scale(1);
        -moz-transform: translateX(-50%) scale(1);
        -ms-transform: translateX(-50%) scale(1);
        -o-transform: translateX(-50%) scale(1);
        transform: translateX(-50%) scale(1);
        -moz-transform: translateX(-50%) scale(1) rotate(0.02deg);
    }
    .no-touch .cd-vertical-nav:hover a::after,
    .touch .cd-vertical-nav a::after {
        opacity: 1;
        -webkit-transition: -webkit-transform 0.4s, opacity 0s;
        -moz-transition: -moz-transform 0.4s, opacity 0s;
        transition: transform 0.4s, opacity 0s;
    }
    .no-touch .cd-vertical-nav:hover .label,
    .touch .cd-vertical-nav .label {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}

.cd-scroll-down {
    position: absolute;
    left: 50%;
    right: auto;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: 20px;
    width: 44px;
    height: 44px;
    background: url(../img/cd-arrow-bottom.svg) no-repeat center center;
}

.no-js .cd-nav-trigger {
    display: none;
}

.no-js .cd-vertical-nav {
    position: static;
    height: auto;
    width: 100%;
    max-width: none;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 0;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}

.no-js .cd-vertical-nav::before {
    display: none;
}

.no-js .cd-vertical-nav ul::after {
    clear: both;
    content: "";
    display: table;
}

.no-js .cd-vertical-nav li {
    display: inline-block;
}

.no-js .cd-vertical-nav a {
    padding: 0 1em;
    margin: 1.5em 1em;
    color: #eaf2e3;
}

.no-js .cd-vertical-nav a.active {
    color: #c0a672;
}

.no-js .cd-vertical-nav a::after,
.no-js .cd-vertical-nav a::before {
    display: none;
}

.no-js .cd-vertical-nav .label {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
}

.diamond-box-inner a {
    cursor: default;
}

 .client-triangles .diamond-box-wrap {
    display: none;

   &.visible {
         display: block;
     }
 }
/*# sourceMappingURL=css/maps/styles.css.map */