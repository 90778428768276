.contact-top {
    @media (min-width: 768px) {
        margin-bottom: 50px;
        h1 {
            margin-bottom: 50px;
        }
    }
}

.contact-brdr {
    width: 94%;
    height: 15%;
    margin-top: -70px;
    margin-right: -6%;
    display: none;
    @media (min-width: 425px) {
        width: 120%;
        height: 15%;
        margin-top: -80px;
        margin-left: -6%;
    }
    @media (min-width: 768px) {
        width: 95%;
        height: 15%;
        margin-top: -80px;
        margin-left: 5%;
        display: block;
    }
    @media (min-width: 1024px) {
        width: 100%;
        height: 35%;
        margin-top: -150px;
        margin-left: 3%;
        display: block;
    }
    @media (min-width: 1440px) {
        width: 95%;
        height: 35%;
        margin-top: -150px;
        margin-left: 5%;
        display: block;
    }
}

.contact-side-container {
    @media (min-width: 768px) {
        display: flex;
        width: 90%;
        margin: 0 auto;
    }
}

.contact-side {
    width: 90%;
    margin: 0 auto;
    position: relative;
    @media (min-width: 768px) {
        width: 50%;
        float: left;
        padding: 0 15px;
        min-height: 300px;
    }
    @media (min-width: 1024px) {
        width: 40%;
    }
    h2 {
        text-transform: uppercase;
    }
    a {
        color: #fff !important;
        cursor: pointer;
        display: block;
        font-size: 16px;
        font-weight: 400;
        line-height: 45px;
        margin: 0 auto;
        text-decoration: none;
        text-transform: uppercase;
        width: 100%;
        border: 1px solid;
        overflow: hidden;
        position: relative;
        @media (min-width: 768px) {
            position: absolute;
            bottom: 0;
            width: 90%;
            right: 0;
            left: 0;
        }
    }
}

.contact-inner-left {
    width: 40%;
    float: left;
    text-align: left;
    span {
        width: 100%;
        margin: 10px 0;
        display: block;
    }
}

.contact-inner-right {
    width: 60%;
    float: left;
    text-align: left;
    span {
        width: 100%;
        margin: 10px 0;
        display: block;
    }
}

.no-style {
    position: relative !important;
    width: auto !important;
    height: auto !important;
    text-transform: none !important;
    margin: 0 !important;
    border: none !important;
    line-height: 1em !important;
}

.md-form .fa {
    font-size: 2.4rem;
    border-radius: 0;
    padding: 10px;
    background: none;
    // color: #E05054;
    color: rgba(0, 102, 147, 1);
}

.md-form label.active {
    font-size: 1.35rem;
    color: #27AAE1;
    -webkit-transform: translateY(-75%);;
    -moz-transform: translateY(-75%);;
    -ms-transform: translateY(-75%);;
    -o-transform: translateY(-75%);;
    transform: translateY(-75%);

    i {
        color: #E05054;
    }
}

.md-form label {
    // color: rgba(0, 102, 147, 1);
    color: #fff;
    position: absolute;
    top: .8rem;
    left: 0;
    font-size: 1.4rem;
    cursor: text;
    -webkit-transition: .2s ease-out;
    -moz-transition: .2s ease-out;
    -o-transition: .2s ease-out;
    -ms-transition: .2s ease-out;
    transition: .2s ease-out;
}


textarea.md-textarea:focus:not([readonly]) {
    border-bottom: 1px solid #27AAE1;
    box-shadow: 0 1px 0 0 #27AAE1;
}

textarea.md-textarea:focus:not([readonly])+label {
    color: #27AAE1;
}

input[type=text]:focus:not([readonly]) {
    border-bottom: 1px solid #27AAE1;
    box-shadow: 0 1px 0 0 #27AAE1;
}



input[type=text]:focus:not([readonly])+label {
    color: #27AAE1;
}

input[type=email]:focus:not([readonly]) {
    border-bottom: 1px solid #27AAE1;
    box-shadow: 0 1px 0 0 #27AAE1;
}



input[type=email]:focus:not([readonly])+label {
    color: #27AAE1;
}



input[type=number]:focus:not([readonly]) {
    border-bottom: 1px solid #27AAE1;
    box-shadow: 0 1px 0 0 #27AAE1;
}



input[type=number]:focus:not([readonly])+label {
    color: #27AAE1;
}

.contact-container {
    a:hover {
        color: #fff;
    }
}

.careers-link {
    a:hover {
        color: #E05054;
    }
}