/* -------------------------------- 

Primary style

-------------------------------- */

*,
*::after,
*::before {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

body {
    font-size: 1.6rem;
    font-family: "Open Sans", sans-serif;
    color: #ffffff;
    background-color: #22283f;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body::before {
    /* never visible - this is used in jQuery to check the current MQ */
    content: 'mobile';
    display: none;
}

@media only screen and (min-width: 1050px) {
    body::before {
        /* never visible - this is used in jQuery to check the current MQ */
        content: 'desktop';
    }
}

a {
    color: rgb(255, 255, 255);
    text-decoration: none;
}

a:hover+a:focus+a:active {
    color: rgb(207, 200, 200) !important;
    text-decoration: none;
}

a:hover {
    color: #E05054;
}

#FWsection0 .container {
    width: 100%;

    padding-left: 0%;
    padding-right: 0%;

    @media (min-width: 768px) {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

.slick-dotted.slick-slider {
    z-index: 1;
    position: relative;
}

.breadcrumb-container {
    background: none;
    position: absolute;
    top: 1%;
    margin-left: 25%;
    margin-top: 1%;
    display: none;

    @media (min-width: 768px) {
        display: block;
    }
}

.cd-section {
    overflow: hidden;
    height: 100vh;
    text-align: center;
    background: rgb(1, 26, 52);
    /* Old browsers */
    background: -moz-linear-gradient(-45deg, rgba(1, 26, 52, 1) 0%, rgba(0, 102, 147, 1) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, rgba(1, 26, 52, 1) 0%, rgba(0, 102, 147, 1) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, rgba(1, 26, 52, 1) 0%, rgba(0, 102, 147, 1) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

    // @media (min-width: 768px) {
    //     min-height: 900px;
    // }
}

.cd-section-single {
    overflow: hidden;
    height: auto;
    text-align: center;
    background: rgb(1, 26, 52);
    /* Old browsers */
    background: -moz-linear-gradient(-45deg, rgba(1, 26, 52, 1) 0%, rgba(0, 102, 147, 1) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, rgba(1, 26, 52, 1) 0%, rgba(0, 102, 147, 1) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, rgba(1, 26, 52, 1) 0%, rgba(0, 102, 147, 1) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

    @media (min-width: 768px) {
        min-height: 900px;
    }
}

.project-section-single {
    overflow: hidden;
    text-align: center;
    background: rgb(1, 26, 52);
    /* Old browsers */
    background: -moz-linear-gradient(-45deg, rgba(1, 26, 52, 1) 0%, rgba(0, 102, 147, 1) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, rgba(1, 26, 52, 1) 0%, rgba(0, 102, 147, 1) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, rgba(1, 26, 52, 1) 0%, rgba(0, 102, 147, 1) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    height: auto;

    @media (min-width: 768px) {
        height: 100%;
    }

    @media (min-width: 768px) {
        min-height: unset;
    }

    @media (min-width: 1440px) {
        min-height: 900px;
    }
}

.main-project-section {
    height: 100%;
}

.pagination-section {
    
    height: 100%;
}

.career-section-single {
    overflow: hidden;
    padding-top: 7% !important;
    height: 100%;
    text-align: center;
    background: #011a34;
    background: -moz-linear-gradient(-45deg, #011a34 0%, #006693 100%);
    background: -webkit-linear-gradient(-45deg, #011a34 0%, #006693 100%);
    background: linear-gradient(135deg, #011a34 0%, #006693 100%);
    
    @media (min-width: 768px) {
        min-height: 900px;
    }
}
.story-section {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.cd-section-single.story-section {
    padding-top: 0 !important;
    min-height: initial;
}
.story-section-single {
    overflow: hidden;
    text-align: center;
    background: #011a34;
    background: -moz-linear-gradient(-45deg, #011a34 0%, #006693 100%);
    background: -webkit-linear-gradient(-45deg, #011a34 0%, #006693 100%);
    background: linear-gradient(135deg, #011a34 0%, #006693 100%);    
    height: auto;

    @media (min-width: 768px) {
        height: 100%;
    }
}

.story-panel {
    height: 100%;

    @media (min-width: 768px) {
        display: flex !important; 
        align-items: center; 
        justify-content: center; 
    }

    .left {
        text-align: center;
    }

    .left img {
        margin-left: auto;
        margin-right: auto;
        margin-top: 85px;
        margin-bottom: 20px;
        padding-left: 50px;
        padding-right: 50px;

        @media (min-width: 768px) {
            margin-top: 0;
            margin-bottom: 0;
            padding-left: 0;
            padding-right: 0;
        }
    }

    .left .section-copy {
        margin: 0 5% 0 5%;

        @media (min-width: 768px) {
            margin: 0% 25% 0 25%;
        }

        @media (min-width: 1024px) {
            margin: 0% 10% 0 10%;
        }
        @media (min-width: 1200px) {
            
        }
        @media (min-width: 1440px) {
            margin: 0% 25% 0 25%;
        }
    }

    .right {
        text-align: center;
    }

    .right img {
        margin-left: auto;
        margin-right: auto;
        margin-top: 85px;
        margin-bottom: 20px;
        padding-left: 50px;
        padding-right: 50px;

        @media (min-width: 768px) {
            margin-top: 0;
            margin-bottom: 0;
            padding-left: 0;
            padding-right: 0;
        }
    }

    .right .section-copy {
        margin: 0 5% 0 5%;

        @media (min-width: 768px) {
            margin: 0% 25% 0 25%;
        }

        @media (min-width: 1024px) {
            margin: 0% 10% 0 10%;
        }
        @media (min-width: 1200px) {
            
        }
        @media (min-width: 1440px) {
            margin: 0% 25% 0 25%;
        }
    }
}


.work-overview-section {
    overflow: inherit;
    height: auto;
    max-height: initial !important;

    .footer-container {
        bottom: unset;
    }
}
.client-overview-section {
    overflow: initial;
    max-height: initial !important;
    min-height: unset;

    @media (min-width: 768px) {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
    }

    .footer-container {
        bottom: 0%;
    }

    .inner-page-content {
        padding: 70px 0px;

        @media (min-width: 768px) {
            padding: 0;
        }
    }
}

.services-section-single {
    height: auto;

    @media (min-width: 768px) {
        height: 100%;
    }

    .intial-container {
        @media (min-width: 768px) {
            display: flex;
        }
    }
}

.services-section-single {

    @media (min-width: 768px) {
        display: flex;
        align-items: center;
    }
}

.full-careers {
    margin-top: 20%;

    @media (min-width: 768px) {
        margin-top: 2%;
    }
}

.portfolio {
    margin-top: 5px;
    .inner {

        @media (min-width: 768px) {
            padding: 0px 15%;
        }
    }
    .left {
        img {
            margin: 0 auto;
            margin-bottom: 30px;
            width: 60%;

            @media (min-width: 768px) {
                width: 50%;
            }

            @media (min-width: 1440px) {
                margin: 0 auto;
            }
        }
        .content {
            margin-top: -50px;
        }
    }
    .right {
        padding: 0px 50px;

        img {

        }
    }

    .row {

        @media (min-width: 768px) {
            display: flex !important;
            align-items: center;
            justify-content: center;
        }

    }
}

.featured-solution {
    background: #fff;
    border-radius: 20px;
    width: 100%;
    height: 120px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 768px) {       
        width: 35%;
        height: 130px;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 10px;
        margin-right: 10px;
    }

    @media (min-width: 1440px) {     
        height: 150px;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    
    display: flex !important;
    align-items: center;
    justify-content: center;

    img {
        margin-left: auto;
        margin-right: auto;
    }
}

.detailed-solution {
    
    background: #fff;
    border-radius: 20px;
    width: auto;
    height: 120px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;

    
    display: flex !important;
    align-items: center;
    justify-content: center;

    @media (min-width: 768px) {
        width: 400px;
        height: 130px;
    }

    img {
        margin-left: auto;
        margin-right: auto;        
        padding-left: 10px;
        padding-right: 10px;
    }

}

.featured-solutions-container {

    @media (min-width: 768px) {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
}

.solution-block {

    @media (min-width: 768px) {
        margin-top: 50px;
        margin-bottom: 50px;
        min-height: 450px;
        max-height: 450px;
        overflow-y: hidden;

    }

    .section-copy {
        padding-left: 10px;
        padding-right: 10px;

        @media (min-width: 768px) {
            padding-left: 100px;
            padding-right: 100px;
        }
    }
}
.container.scroller-container {
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;    
    z-index: 3;
}

.scroll-icon {
    .fa {
        background: transparent;
        color: #fff;
        font-size: 2em;
        
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;

        @media (min-width: 768px) {
            font-size: 3em;
        }
    }
}
.text-ad-links {
    display: none;
}
.portfolio-section {
    .left {
        padding: 0;


    }
    .right {
        padding: 0;

        @media (min-width: 768px) {
            padding: 0;
        }
    }
}

.project-page-link {
    position: relative;

    h1 {
        font-weight: 300;
        color: #fff;
        font-size: 1.5em;

        @media (min-width: 768px) {
            font-size: 2.5em;
        }

        @media (min-width: 1440px) {
            font-size: 4.8rem;
        }
    }
}
.panel-previous {
    
    display: flex !important;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.panel-next {
    display: flex !important;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.next-prev-fa {
    font-size: 3rem;    
    border-radius: 0;
    padding: 0;
    background: none;
    color: #fff;
    font-weight: 100;

    @media (min-width: 768px) {
        font-size: 8rem;
    }

}

.portfolio-panel {
    height: 100%;

    @media (min-width: 768px) {
        display: flex !important;
        align-items: center;
        justify-content: center;
    }

    

    .left img {
        margin-left: auto;
        margin-right: auto;
    }

    .right img {
        margin-left: auto;
        margin-right: auto;
    }
}

.left {

    .section-copy {
        margin: 0 5% 0 5%;

        @media (min-width: 768px) {
            margin: 0 25% 0 25%;
        }
    }

    .section-copy img {
        width: 50%;

        @media (min-width: 768px) {
            width: auto;
        }
    }


}

.right {

    .section-copy {
        margin: 0 5% 0 5%;

        @media (min-width: 768px) {
            margin: 0 25% 0 25%;
        }
    }

    .section-copy img {
        width: 50%;

        @media (min-width: 768px) {
            width: auto;
        }
    }

}

.section-copy {
    h1 {
        font-size: 1.5em;

        @media (min-width: 768px) {
            font-size: 2em;
        }

        @media (min-width: 1440px) {
            font-size: 4.8rem;
        }
    }
    

    p {
        font-size: 1.1em;
        line-height: 1.1;

        @media (min-width: 768px) {
            font-size: 0.9em;
            line-height: normal;
        }

        @media (min-width: 1440px) {
            font-size: 1.8rem;
        }
    }
}
.vert {
    transform: rotate(90deg);
    position: relative;
    right: -300px;
    top: -300px;
    float: right;
    display: none;
    span {
        font-size: 140px;
        line-height: 0px;
        bottom: 0px;
        position: relative;
        opacity: 0.2;
        font-weight: bold;
    }
}

.section-vert {
    transform: rotate(90deg);
    position: relative;
    left: -350px;
    top: -60%;
    float: left;
    display: none;
    span {
        font-size: 140px;
        line-height: 0px;
        bottom: 0px;
        position: relative;
        opacity: 0.2;
        font-weight: bold;
    }
}

.col-xs-12.col-md-7.right {
    // top: -365px;

    @media (min-width: 380px) {
        // top: -410px;
    }

    @media (min-width: 768px) {
        top: unset;
    }
}
.col-xs-12.col-md-5.left {
    // top: 120px;

    @media (min-width: 768px) {
        top: unset;
    }
}
// .bg-container {
//     background: url(/assets/img/Plascon_splatter.png) right 0px no-repeat;
//     height: 100%;
// }

.bg-container-section {
    // background: url(/assets/img/Plascon/Plascon_media_bg.png) 64vh 44vh no-repeat;
    height: 100%;
}

.breadcrumb>li+li:before {
    padding: 0 5px;
    color: #ccc;
    content: "\003e";
}

.breadcrumb>ul>li>a:hover+a:focus+a:active {
    color: rgba(255, 255, 255, 0.2);
    text-decoration: none;
}

.breadcrumb {
    float: left;
    background: none;
}

.breadcrumb>.active {
    color: white;
}

.footer-content {
    list-style: none;

    li {
        float: left;
        padding-left: 5px;
        padding-right: 5px;
        font-weight: 100;
    }
}

.footer {
    img {
        margin-left: auto;
        margin-right: auto;
    }
}

.footer-container {
    position: relative;
    bottom: 0;
    margin: 0 auto;

    @media (min-width: 768px) {
        bottom: -25%;
        padding-right: 75px;
        padding-left: 75px;
    }
    @media(min-width: 992px) {
        padding-right: 75px;
        padding-left: 75px;
    }
    @media (min-width: 1024px) {
        bottom: -15%;
        padding-right: 150px;
        padding-left: 150px;
    }
    @media (min-width: 1440px) {
        bottom: -25%;
    }
    @media (min-width: 1600px) {
        padding-right: 250px;
        padding-left: 250px;
    }

    a {
        color: #fff;
    }

    a:hover {
        color: #E05054;
    }

    .footer-inner {
        margin-bottom: 25px;
        float: left;
        padding: 0 0;
        display: block;

        @media (min-width: 768px) {
            display: flex;
            align-items: center;
        }
    }
    .inner-1 {
        width: 47%;
        padding-top: 5px;
        display: none;

        @media (min-width: 768px) {
            display: initial;
        }
    }
    .inner-2,
    .inner-3,
    .inner-4 {
        width: 30%;
        margin: 5px;

        @media (min-width: 768px) {
            width: 16%;
            margin: 0;
        }
    }
    .footer-btn {
        color: #fff !important;
        cursor: pointer;
        display: block;
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        margin: 5px auto;
        text-decoration: none;
        text-transform: uppercase;
        margin-top: 0px;
        border: 1px solid;
        overflow: hidden;
        position: relative;
        border-radius: 10px;
        padding: 5px;
    }

    .footer-top {
        display: none;

        @media (min-width: 768px) {
            display: block;
        }

    }
}

.career-section-single {
    .footer-container {
        bottom: 0;
    }
}

.particles-js-canvas-el {
    position: absolute;
    top: 0px;
    z-index: 0;
    opacity: 0.4;
}

/* Company Details page */
.table-stripes>tbody>tr:nth-of-type(odd) {
    background-color: transparent;
    filter: alpha(opacity=10);
    -moz-opacity: 0.1;
    opacity: 1;
}
.table-stripes>tbody>tr:nth-of-type(even) {
    background-color: transparent;
    filter: alpha(opacity=10);
    -moz-opacity: 0.1;
    opacity: 1;
}
.table-stripes>tbody>tr>td {
    color:#ffffff;
    }

.table-stripes {
 border:#ffffff;

}
.CPLink:hover {
    color: rgb(255, 94, 94);
}

/* Services */
  .expand {
    cursor: pointer;
    display: inline-block;
    padding: 0 .5em;
    color: rgb(0, 0, 0);
    font-size: 10px;
    line-height: 2;
    border-radius: 30px; 

  }
  
.serviceStyle {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    // flex-basis: 31%;
    margin: 1%;
    padding: 1%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (min-width: 768px) {
        width: 22%; 
    }
}
.serviceStyle>strong {
    color: #ffffff;

}
.serviceStyle>span {
    color: #1157A0;
    font-size:11px;

}

.service-caption {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 0;
}

#1::selection .serviceStyle  {
    background-color: rgba(255, 255, 255, 1);

}

.services-fa {
    font-size: 2rem;
    border-radius: 50%;
    color: #fff;
    background: transparent;;
    padding: 0;
}

.serviceStyle.expanded {
    transition: all .1s ease-out;
    background:#fff;
    color: #0E549F;
    height: auto;

    @media (min-width: 768px) {
        margin-bottom: 0;
        z-index: 2;
    }

    .services-fa {
        color: rgba(0, 102, 147, 0.9);
        background: transparent;
        padding: 0;
    }

    .service-caption {
        margin-top: 10px;
    }

    cursor: pointer;

    strong {
        color: #0E549F;
    }
}
.collapse.in {
    transition: all .1s ease-out;
}
.learnMore {
    color: #0E549F;
    text-decoration:underline;
    font-weight:600;
}

.services-overview-section {
    height: 100%;
}

.services-header {
    

    @media (min-width: 768px) {
        font-size: 6rem !important;
    }
}

.service-row {

    @media (min-width: 768px) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.service-chevron {
    background: url(../img/Arrow_down.png);
}

.highlight-text {
    font-size: 1.2em;
    font-weight: 100;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
    display: flex;
}

.footer-content li.active {
    color: #E05054;
}

.footer i.fa {
    margin-left: 5px;
}

.chevron-scroll-down {
    background: url(/assets/img/Arrow_down.png);
    width: 65px;
    background-size: cover;
    height: 32px;
    padding: 15px;
    margin-bottom: 10px;

    @media (min-width: 768px) {
        padding: 15px;
    }
}

.chevron-scroll-up {
    background: url(/assets/img/Arrow_up.png);
    width: 65px;
    background-size: cover;
    height: 32px;
    padding: 15px;
    margin-bottom: 10px;

    @media (min-width: 768px) {
        padding: 15px;
    }
}

.chevron-previous {
    padding: 15px;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 50px;


    @media (min-width: 768px) {
        padding: 15px;
        width: auto;
    }
}

.chevron-next {
    padding: 15px;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 50px;


    @media (min-width: 768px) {
        padding: 15px;
        width: auto;
    }
}

hr {
    margin-top: 0;
    margin-bottom: 10px;
}

.row.footer-img-row {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.footer-bottom {
    .contact-number {
        padding: 0 0;
        text-align: left;
        display: flex;
        justify-content: center;
        margin-bottom: 10px;

        @media (min-width: 768px) {
            justify-content: baseline;
            align-items: center;
            margin-bottom: 0;
        }
    }

    .social-details {
        padding: 0 0;
        text-align: left;
        display: flex;
        justify-content: center;

        @media (min-width: 768px) {
            justify-content: baseline;
        }
    }
}
