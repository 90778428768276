.shake-slow.shake-constant {
    animation-duration: 90s;
}

.shake-vertical:hover, .shake-trigger:hover .shake-vertical, .shake-vertical.shake-freeze, .shake-vertical.shake-constant {
    animation-duration: 45s;
}

.diamond-box-inner {
    // -webkit-filter: blur(2px);
}


#projector{
	position: absolute;
    left: 0;
	margin-top: -150px;
	opacity: 0.4;
}    

/* Client Logo shuffle */
.client-logo {
	// width: 250px; 
}

.logo-shuffle {
	list-style-type: none;

	li {
		margin: 0px; 
		padding: 0px;
		margin-left: auto;
		margin-right: auto;
	}
}

@keyframes wave-flow {
	0% {
		box-shadow: 0 0 8px 6px linear-gradient(135deg, rgba(1, 26, 52, 1) 0%, rgba(0, 102, 147, 1) 100%), 0 0 12px 10px #0E549F, 0 0 12px 14px #ff1a1a;
	}
	10% {
		box-shadow: 0 0 8px 6px rgba(0, 102, 147, 1), 0 0 12px 10px rgba(0, 102, 147, 1), 0 0 12px 14px #0E549F;
	}
	100% {
		box-shadow: 0 0 8px 6px linear-gradient(135deg, rgba(1, 26, 52, 1) 0%, rgba(0, 102, 147, 1) 100%) 0 0 4px 40px #333, 0 0 4px 41px rgba(255, 26, 26, 0);
	}
}

.s {
    background: transparent;
	border-radius: 50%;
	height: 35px;
	width: 35px;
}

.s:hover {
    background: rgba(255, 255, 255, 0.2);
    -webkit-animation: wave-flow 3.5s 0.4s ease-out infinite;
    -moz-animation: wave-flow 3.5s 0.4s ease-out infinite;
    animation: wave-flow 3.5s 0.4s ease-out infinite;
}

.nav-active {
    background: rgba(255, 255, 255, 0.2);
    -webkit-animation: wave-flow 3.5s 0.4s ease-out infinite;
    -moz-animation: wave-flow 3.5s 0.4s ease-out infinite;
    animation: wave-flow 3.5s 0.4s ease-out infinite;
}
.portfolio .animated {
	animation-duration: 1.5s;
}

.hidden{
     opacity:0;
}
.visible{
     opacity:1;
}

.revealOnScroll { 
	opacity: 0; 
}

.pagination-section .animated {
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.portfolio-section .animated {
    -webkit-animation-duration: 2.5s;
    animation-duration: 2.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.portfolio-panel .left {
	position: relative;
}

.portfolio-panel .right {
	position: relative;
}

.nav-animation {
	animation-duration: 40s !important;
}

@keyframes animateleft {
    from {
        left: -300px;
        opacity: 0
    }

    to {
        left: 0;
        opacity: 1
    }
}

@keyframes animateright {
    from {
        right: -300px;
        opacity: 0
    }

    to {
        right: 0;
        opacity: 1
    }
}

[data-aos="from-right"] {
	transform: skew(140deg,-10deg);
	right: -50%;
	opacity: 0;
	-webkit-transition-property: transform, right, opacity;
	transition-property: transform, right, opacity;
}
[data-aos="from-right"].aos-animate {
	right: 0;
	opacity: 1;
	transform: skew(180deg,0);
}

[data-aos="from-left"] {
	transform: skew(20deg,10deg);
	left: -50%;
	opacity: 0;
	-webkit-transition-property: transform, left, opacity;
	transition-property: transform, left, opacity;
}
[data-aos="from-left"].aos-animate {
	left: 0;
	opacity: 1;
	transform: skew(0,0);
}

@keyframes neon2 {
	from {
	  box-shadow: 0 0 20px #fff;
	  box-shadow: 0 0 30px #fff;
	  box-shadow: 0 0 40px #fff; 
	  box-shadow: 0 0 70px #fff; 
	  box-shadow: 0 0 80px #fff; 
	  box-shadow: 0 0 100px #fff;
	  box-shadow: 0 0 150px #fff;
	}
	to {
	  box-shadow: 0 0 10px #fff;
	  box-shadow: 0 0 15px #fff; 
	  box-shadow: 0 0 20px #fff;
	  box-shadow: 0 0 35px #fff; 
	  box-shadow: 0 0 40px #fff; 
	  box-shadow: 0 0 50px #fff; 
	  box-shadow: 0 0 75px #fff;
	}
}
