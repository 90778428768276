#FWsection1,
#FWsection2,
#FWsection3,
#FWsection4,
#FWsection5,
#FWsection6,
#FWsection7,
#FWsection8 {
    padding-top: 40%;
    overflow-y: visible;
	max-height: 100vh;
    @media (min-width: 768px) {
        padding-top: 20%;
    }
    @media (min-width: 1024px) {
        padding-top: 8%;
    }
}

.logo-container {
    cursor: pointer;
    z-index: 2;
    position: fixed;
    background: #fff;
    background-size: contain;
    height: 70px;
    width: 100%;

    @media (min-width: 360px) {

    }
    @media (min-width: 425px) {

    }
    @media (min-width: 768px) {
        z-index: 4;
        width: 40%;
        background: url(../img/logo/Layer3.png) no-repeat;
        background-size: contain;
        height: 230px;
    }
    @media (min-width: 1024px) {
        width: 25%;
    }
    @media (min-width: 1440px) {
        width: 25%;
    }
    .logo-corner {    
        position: relative;
        top: -15px;
        z-index: 7;
        width: 300px;
        margin-left: auto;
        margin-right: auto;

        @media (min-width: 768px) {
            top: 0px;
            position: absolute;
            width: auto;
        }
    }
    &:after {
        content: "";
        display: none;
        background: url(../img/logo/Layer2.png) no-repeat;
        background-size: contain;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 230px;
        z-index: 5;
        animation: Pulsate 3s linear infinite;

        @media (min-width: 768px) {
            display: block;
        }
    }
    &:before {
        content: "";
        display: none;
        background: url(../img/logo/Layer1.png) no-repeat;
        background-size: contain;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 230px;
        z-index: 6;
        animation: Pulsate 4s linear infinite;

        @media (min-width: 768px) {
            display: block;            
        }
    }
    @keyframes Pulsate {
        from {
            opacity: 1;
        }
        50% {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}

.cd-vertical-nav {
    list-style: none;
    z-index: 2;
}

.nav-active.active::before {
    background: rgba(255, 255, 255, 0.2);
}

.client-triangles {
    margin: 0 auto;
    padding: 0 17%;
    .diamond-box {
        border: none;
    }
}

.slider-info {
    h2,
    h3,
    p {
        margin: 25px 0;
    }
    .cd-btn {
        margin: 40px 25px;
    }

    h3 {
        @media (min-width: 1024px) {
            margin: 25px 50px;
        }
        @media (min-width: 1440px) {
            margin: 25px 25px;
        }
    }
}

.bot-buttons {
    position: absolute;
    bottom: 5%;
    width: 100%;  
    display: none;  

    @media (min-width: 1366px) {
        bottom: 4%;
        width: 100%;  
        display: none;
    }


    // @media (min-width: 1024px) {
    //     bottom: 20%;
    // }
    // @media (min-width: 1440px) {
    //     bottom: 4%;
    // }
    .btn {
        flex-wrap: wrap;
        position: relative;
        z-index: 1;
    }
}

.story-container {
    position: relative;
}

// .story-container:before,
// .story-container:after,
// .story-container>:first-child:before,
// .story-container>:first-child:after { 
//     position: absolute;
//     width: 20px;
//     height: 20px;
//     border-color: #fff;
//     border-style: solid;
//     content: ' ';
// }
.story-container:before {
    top: 0;
    left: 0;
    border-width: 1px 0 0 1px
}

.story-container:after {
    top: 0;
    right: 0;
    border-width: 1px 1px 0 0
}

.story-container>:first-child:before {
    bottom: 0;
    right: 0;
    border-width: 0 1px 1px 0
}

.story-container>:first-child:after {
    bottom: 0;
    left: 0;
    border-width: 0 0 1px 1px
}

.contact-container:before {
    left: 25%;
}

.contact-container:after {
    right: 25%;
}

.contact-container>:first-child:before {
    right: 25%;
}

.contact-container>:first-child:after {
    left: 25%;
}

.contact-need-room {
    padding-top: 0;
    padding-bottom: 20px;
    @media (min-width: 768px) {
        padding-bottom: 80px;
    }
}


/********** Masonry ***********/


/* clear fix */

.fancybox-image {
    transform: scale(1);
}

.m-grid {
    background-color: rgba(255, 255, 255, 0.1);
    padding-bottom: 10px;
}

.m-grid:after {
    content: '';
    display: block;
    clear: both;
}


/* ---- .grid-item ---- */

.m-grid-sizer,
.m-grid-item {
    width: 49%;
}

.m-grid-item {
    height: 150px;
    float: left;
    padding: 10px;
    overflow: hidden;
    @media (min-width: 1024px) {
        height: 200px;
    }
}

.m-grid-item img {
    display: block;
    max-width: 100%;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1);
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1);
    transition: all 200ms ease-in;
    transform: scale(1);
}

.m-grid-item:hover {
    overflow: visible;
    height: auto;
    box-shadow: 0px 0px 150px #000000;
    z-index: 2;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1.2);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1.2);
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1.2);
    transition: all 200ms ease-in;
    transform: scale(1.2);
}

.career-full {
    flex-basis: 31%;
    margin: 1%;
    padding: 1%;
    width: 30%;
}

.career-row {
    display: flex;
    flex-wrap: wrap;
}
.career-block.career-full {
    min-width: 80%;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 768px) {
        min-width: auto;
    }
}

.preview-careers {
    padding: 0;
    margin-bottom: 5%;

    @media (min-width: 768px) {
        padding: 0 10%;
        margin-bottom: 0;
    }
}

.full-careers {
    padding: 0 10%;
}

.career-inner {
    display: block;

    @media (min-width: 768px) {
        display: flex;
    }
}

.career-block {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    flex-basis: 31%;
    margin: 1%;
    padding: 1%;
    min-width: 100%;

    @media (min-width: 768px) {
        flex-wrap: wrap;
        min-width: auto;
        width: 31%;
        margin-left: auto;
        margin-right: auto;
    }
    a {
        color: #fff !important;
        cursor: pointer;
        display: block;
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        margin: 5px auto;
        text-decoration: none;
        text-transform: uppercase;
        width: 50%;
        border: 1px solid;
        overflow: hidden;
        position: relative;
        border-radius: 10px;
        padding: 5px;
    }
    p {
        font-size: 2rem;
        line-height: 30px;
        min-height: 155px;
        overflow: hidden;
    }
    .career-title {
        margin-top: 0px;
        text-transform: uppercase;
        font-weight: 700;
        /* height: 20%; */
        font-size: 1.4rem;
        width: auto;
        white-space: normal;
        overflow: hidden;

        @media (min-width: 768px) {
            font-size: 2.4rem;
            white-space: normal;
        }
    }
    hr {
        margin-bottom: 5px;    
        margin-right: auto;
        margin-left: auto;
        display: none;

        @media (min-width: 768px) {
            width: 75%;
            display: block;
        }
    }
}

.career-disc {
    margin: 0 0 10px;
    font-size: 1.2rem;
    max-height: 56px;
    overflow: hidden;
    display: none;

    @media (min-width: 768px) {
        display: initial;
    }


    p {
        font-size: 1.5rem;
    }
}

.career-full .career-disc {
    display: initial;
}

#career-slider {
    .slick-prev {
        left: -10%;

        @media (min-width: 768px) {
            left: -5%;
        }
    }
    .slick-next {
        right: -20px;

        @media (min-width: 768px) {
            right: -25px;
        }
    }
    .slick-next:before, .slick-prev:before {
        font-size: 60px;

        @media (min-width: 768px) {
            font-size: 97px;
        }
    }
}

.view-tenth {
    width: 48%;
    float: left;
    height: 160px;
    padding: 0 15px;
    p {
        max-height: 43px;
        overflow: hidden;
    }
    h2 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.intro-text {
    font-size: 1.5em;
}

.ourwork-headers {
    margin-top: -30px;
    font-size: 5rem;
    h2 {
        font-size: 3rem;

        @media (min-width: 768px) {
            font-size: 5rem;
        }
    }
    h3 {
        font-size: 3rem;
        font-weight: 400;
        @media (min-width: 768px) {
            font-size: 6rem;
        }
        @media (min-width: 1024px) {
            font-size: 4rem;
        }
        @media (min-width: 1440px) {
            font-size: 6rem;
        }
    }
}

.menu-footer {
    h3 {
        margin-top: 0;
    }
}

#balls-container {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 1px;
}

#info {
    position: absolute;
    left: 20px;
    margin-top: 20%;
    opacity: 0.3;
}


/***************** LAMP **********************/

// @import url(http://weloveiconfonts.com/api/?family=entypo);
*,
*:before,
*:after {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.digital-pr-graphics {
    position: absolute;
    width: 100%;
    height: 100%;
    margin-bottom: -200px;
    overflow: hidden;
    display: none;

    @media (min-width: 768px) {
        display: block;
    }

    .fa {
        font-size: 5rem;
        border-radius: 50%;
        color: rgba(0, 102, 147, 0.9);
        background: transparent;
        padding: 10px;
    }
    .col-xs-2.megaphone {
        border: 0;
        transform: rotate(-30deg);
        margin-top: 25%;
        margin-left: -20%;

    }
    .col-xs-2.desktop {
        margin-top: 10%;
    }

    .col-xs-2.laptop {
        margin-top: 25%;
    }

    .col-xs-2.twitter {
        margin-left: -20%;
        transform: rotate(30deg);
    }

    .col-xs-2.cellphone {
        margin-top: 15%;
        transform: rotate(50deg);
    }

    .col-xs-2.cloud-upload {
        margin-top: -10%;
        transform: rotate(-23deg);
    }

    .col-xs-2.cloud-download {
        margin-top: 10%;
        margin-left: -30%;
    }
    
    .col-xs-2 {
        padding-top: 10px;
        padding-bottom: 10px;  
        margin: 10px;
    }
    .detail-icons {
        width: 50%;
        margin-left: -15%;
        margin-top: 5%;
    }
    
    // .col-xs-2:after {
    //     content: '';
    //     position: absolute;
    //     bottom: 0;
    //     left: 50%;
    //     width: 0;
    //     height: 0;
    //     border: 15px solid transparent;
    //     border-top-color: rgba(0, 102, 147, 0.9);
    //     border-bottom: 0;
    //     border-left: 0;
    //     margin-left: -7.5px;
    //     margin-bottom: -15px;
    // }
    
    // .col-xs-2.megaphone:after {
    //     content: '';
    //     position: absolute;
    //     bottom: 0;
    //     left: 50%;
    //     width: 0;
    //     height: 0;
    //     border: 0;
    //     border-top-color: rgba(0, 102, 147, 0.9);
    //     border-bottom: 0;
    //     border-left: 0;
    //     margin-left: -7.5px;
    //     margin-bottom: -15px;
    // }
    
}



#lamp {
    position: relative;
    top: -175px;
    width: 100%;
    height: 300px;
    margin-bottom: -200px;
    overflow: hidden;

    @media (min-width: 768px) {
        top: -300px;

    }

    @media (min-width: 992px) {
        top: -35%;
    }

    @media (min-width: 1200px) {

        
    }

    @media (min-width: 1366px) {
        
    }

    @media (min-width: 1440px) {
        
    }
}

.lamp {
    position: relative;
    margin: 0 auto;
    width: .7rem;
    height: 15rem;
    //background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
    background-repeat: no-repeat;
    background-size: 0.15rem 15rem, .4rem .8rem, .7rem 2rem;
    background-position: 50% 0, 0.19rem 13rem, 0 13.8rem;
    float: left;
    margin: 0% 3%;
}

.lamp-low {
    position: relative;
    margin: 0 auto;
    width: .7rem;
    height: 20rem;
    //background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
    background-repeat: no-repeat;
    background-size: 0.15rem 18rem, .4rem .8rem, .7rem 2rem;
    background-position: 50% 0, 0.19rem 18rem, 0 18.8rem;
    float: left;
    margin: 0% 3%;
}

.lamp-high {
    position: relative;
    margin: 0 auto;
    width: .7rem;
    height: 10rem;
    //background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
    background-repeat: no-repeat;
    background-size: 0.15rem 18rem, .4rem .8rem, .7rem 2rem;
    background-position: 50% 0, 0.19rem 18rem, 0 8.8rem;
    float: left;
    margin: 0% 3%;
}

.lamp:before,
.lamp:after {
    content: '';
    position: absolute;
}

.lamp-low:before,
.lamp-low:after {
    content: '';
    position: absolute;
}

.lamp-high:before,
.lamp-high:after {
    content: '';
    position: absolute;
}

.lamp:before {
    left: -1.65rem;
    bottom: -4rem;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.03);
    box-shadow: inset 2px -2px 10px rgba(255, 255, 255, 0.07);
    transition: all .5s;
    z-index: 3;
}

.lamp-low:before {
    left: -1.65rem;
    bottom: -4rem;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.03);
    box-shadow: inset 2px -2px 10px rgba(255, 255, 255, 0.07);
    transition: all .5s;
}

.lamp-high:before {
    left: -1.65rem;
    bottom: -4rem;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.03);
    box-shadow: inset 2px -2px 10px rgba(255, 255, 255, 0.07);
    transition: all .5s;
}

.gonna-give-light,
.gonna-give-light:before {
    position: absolute;
}

.lamp .gonna-give-light {
    top: 15.05rem;
    left: .25rem;
    width: 0;
    height: 1.5rem;
    border-right: .2rem solid rgba(255, 255, 255, 0.05);
}

.lamp-low .gonna-give-light {
    top: 20.05rem;
    left: .25rem;
    width: 0;
    height: 1.5rem;
    border-right: .2rem solid rgba(255, 255, 255, 0.05);
}

.lamp-high .gonna-give-light {
    top: 10.05rem;
    left: .25rem;
    width: 0;
    height: 1.5rem;
    border-right: .2rem solid rgba(255, 255, 255, 0.05);
}

.gonna-give-light:before {
    content: '';
    top: 1.5rem;
    left: -.35rem;
    width: .9rem;
    height: .9rem;
    border-radius: 50%;
    border: .2rem solid rgba(255, 255, 255, 0.05);
    box-shadow: 0px 0px 50px rgba(255, 255, 255, 0);
}

.lamp.lamp-on:before,
.lamp-low.lamp-on:before,
.lamp-high.lamp-on:before {
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 10px rgba(255, 255, 255, 0.8), 0px 5px 50px rgba(255, 255, 255, 0.8), 0px 8px 80px rgba(255, 255, 255, 0.6), 0px 8px 120px rgba(255, 255, 255, 0.6);
    opacity: .6;
}

.cd-section.home-page-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0 !important;
    justify-content: center;
}

.slider-info p {
    margin: 25px 0px 35px 0;
}