/* SLIDER */

.cd-hero {
    position: relative;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.cd-hero-slider {
    position: relative;
    height: 90%;
    width: 75%;
    margin: 0 auto;
    overflow: hidden;
    list-style: none;
    padding: 0px;
}

.cd-hero-slider li {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
}

.cd-hero-slider li.selected {
    position: relative;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
}

.cd-hero-slider li.move-left {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
}

.cd-hero-slider .cd-img-container img {
    position: absolute;
    left: 50%;
    bottom: auto;
    right: auto;
    width: 80%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.cd-img-container {
    top: 15% !important;
    @media (min-width: 425px) {
        top: 20% !important;
    }
    @media (min-width: 580px) {
        top: 25% !important;
    }
    @media (min-width: 680px) {
        top: 30% !important;
    }
}

.slider-info {
    @media (min-width: 425px) {
    }
    @media (min-width: 450px) {
    }
    @media (min-width: 680px) {
    }
    @media (min-width: 768px) {
    }
}

.img-container.right-image {
    @media (min-width: 380px) {
    }
    @media (min-width: 768px) {
    }
    @media (min-width: 992px) {
        top: 0;
        text-align: right;
    }

    img {
        width: 65%;
    }
}

.img-container.left-image {

    @media (min-width: 380px) {
    }
    @media (min-width: 768px) {
    }
    @media (min-width: 992px) {
        top: 0;
        text-align: left;
    }

    img {
        width: 65%;
    }
}

.slider-info.text-left.info-with-banner {
    text-align: center;

    @media (min-width: 380px) {
    }
    @media (min-width: 425px) {
    }
    @media (min-width: 450px) {
    }
    @media (min-width: 680px) {
    }
    @media (min-width: 768px) {
    }
    @media (min-width: 992px) {
        text-align: left;
    }
    @media (min-width: 1024px) {
    }
    @media (min-width: 1440px) {
    }

    p {
        margin: 0 0 25px 0;

        @media (min-width: 992px) {
            padding-right: 45%;
        }
    }
}

.slider-info.text-right.info-with-banner {
    text-align: center;

    @media (min-width: 380px) {
    }

    @media (min-width: 425px) {
    }
    @media (min-width: 450px) {
    }
    @media (min-width: 680px) {
    }
    @media (min-width: 768px) {
    }
    @media (min-width: 992px) {
        text-align: right;
    }

    p {
        margin: 0 0 25px 0;

        @media (min-width: 992px) {
            padding-left: 45%;
        }
    }
}

.cd-hero-slider h2 {
    font-size: 2.4rem;
}

.cd-hero-slider p {
    font-size: 1.4rem;
    line-height: 1.4;
}

.cd-hero-slider .cd-btn {
    color: #ffffff;
    border: 1px solid #ffffff;
    background-color: transparent;
    font-family: "Nexa-Light", sans-serif;
    padding: 10px 20px 6px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 15px;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 18px;
    font-size: 1.8rem;
    margin: 0;
}

.cd-hero-slider .cd-btn:hover {
    border: 1px solid #ffffff;
    text-decoration: none;
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.2);
    -webkit-transition: all 200ms ease-out 0.2s;
    -moz-transition: all 200ms ease-out 0.2s;
    -o-transition: all 200ms ease-out 0.2s;
    transition: all 200ms ease-out 0.2s;
}

.cd-hero-slider .cd-btn.secondary {
    background-color: rgba(22, 26, 30, 0.8);
}

.cd-hero-slider li.is-moving,
.cd-hero-slider li.selected {
    -webkit-transition: -webkit-transform 0.5s;
    -moz-transition: -moz-transform 0.5s;
    transition: transform 0.5s;
}

.cd-hero-slider .cd-full-width,
.cd-hero-slider .cd-half-width {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    left: 0;
    top: 0;
    padding-top: 100px;
    text-align: center;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
}

@media only screen and (min-width: 768px) {
    .cd-slider-nav {
        height: 80px;
    }
    .cd-slider-nav a {
        padding-top: 48px;
        font-size: 1.1rem;
        text-transform: uppercase;
    }
    .cd-slider-nav a::before {
        top: 18px;
    }
    .cd-hero-slider li:nth-of-type(2) {
        background-image: none;
    }
    .cd-hero-slider li:nth-of-type(3) {
        background-image: none;
    }
    .cd-hero-slider li:nth-of-type(4) {
        background-image: none;
    }
    .cd-hero-slider .cd-bg-video-wrapper {
        display: block;
    }
    .cd-hero-slider .cd-half-width {
        width: 45%;
    }
    .cd-hero-slider .cd-half-width:first-of-type {
        left: 5%;
    }
    .cd-hero-slider .cd-half-width:nth-of-type(2) {
        right: 5%;
        left: auto;
    }
    .cd-hero-slider .cd-img-container {
        display: block;
    }
    .cd-hero-slider h2 {
        font-size: 2.4em;
        font-weight: 300;
    }
    .cd-hero-slider .cd-btn {
        font-size: 1.4rem;
    }
    .cd-hero-slider .cd-half-width {
        opacity: 0;
        -webkit-transform: translateX(40px);
        -moz-transform: translateX(40px);
        -ms-transform: translateX(40px);
        -o-transform: translateX(40px);
        transform: translateX(40px);
    }
    .cd-hero-slider .move-left .cd-half-width {
        -webkit-transform: translateX(-40px);
        -moz-transform: translateX(-40px);
        -ms-transform: translateX(-40px);
        -o-transform: translateX(-40px);
        transform: translateX(-40px);
    }
    .cd-hero-slider .selected .cd-half-width {
        opacity: 1;
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
    .cd-hero-slider .is-moving .cd-half-width {
        -webkit-transition: opacity 0s 0.5s, -webkit-transform 0s 0.5s;
        -moz-transition: opacity 0s 0.5s, -moz-transform 0s 0.5s;
        transition: opacity 0s 0.5s, transform 0s 0.5s;
    }
    .cd-hero-slider li.selected.from-left .cd-half-width:nth-of-type(2),
    .cd-hero-slider li.selected.from-right .cd-half-width:first-of-type {
        -webkit-transition: opacity 0.4s 0.2s, -webkit-transform 0.5s 0.2s;
        -moz-transition: opacity 0.4s 0.2s, -moz-transform 0.5s 0.2s;
        transition: opacity 0.4s 0.2s, transform 0.5s 0.2s;
    }
    .cd-hero-slider li.selected.from-left .cd-half-width:first-of-type,
    .cd-hero-slider li.selected.from-right .cd-half-width:nth-of-type(2) {
        -webkit-transition: opacity 0.4s 0.4s, -webkit-transform 0.5s 0.4s;
        -moz-transition: opacity 0.4s 0.4s, -moz-transform 0.5s 0.4s;
        transition: opacity 0.4s 0.4s, transform 0.5s 0.4s;
    }
    .cd-hero-slider .cd-full-width h2,
    .cd-hero-slider .cd-full-width p,
    .cd-hero-slider .cd-full-width .cd-btn {
        opacity: 0;
        -webkit-transform: translateX(100px);
        -moz-transform: translateX(100px);
        -ms-transform: translateX(100px);
        -o-transform: translateX(100px);
        transform: translateX(100px);
    }
    .cd-hero-slider .move-left .cd-full-width h2,
    .cd-hero-slider .move-left .cd-full-width p,
    .cd-hero-slider .move-left .cd-full-width .cd-btn {
        opacity: 0;
        -webkit-transform: translateX(-100px);
        -moz-transform: translateX(-100px);
        -ms-transform: translateX(-100px);
        -o-transform: translateX(-100px);
        transform: translateX(-100px);
    }
    .cd-hero-slider .selected .cd-full-width h2,
    .cd-hero-slider .selected .cd-full-width p,
    .cd-hero-slider .selected .cd-full-width .cd-btn {
        opacity: 1;
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
    .cd-hero-slider li.is-moving .cd-full-width h2,
    .cd-hero-slider li.is-moving .cd-full-width p,
    .cd-hero-slider li.is-moving .cd-full-width .cd-btn {
        -webkit-transition: opacity 0s 0.5s, -webkit-transform 0s 0.5s;
        -moz-transition: opacity 0s 0.5s, -moz-transform 0s 0.5s;
        transition: opacity 0s 0.5s, transform 0s 0.5s;
    }
    .cd-hero-slider li.selected h2 {
        -webkit-transition: opacity 0.4s 0.2s, -webkit-transform 0.5s 0.2s;
        -moz-transition: opacity 0.4s 0.2s, -moz-transform 0.5s 0.2s;
        transition: opacity 0.4s 0.2s, transform 0.5s 0.2s;
    }
    .cd-hero-slider li.selected p {
        -webkit-transition: opacity 0.4s 0.3s, -webkit-transform 0.5s 0.3s;
        -moz-transition: opacity 0.4s 0.3s, -moz-transform 0.5s 0.3s;
        transition: opacity 0.4s 0.3s, transform 0.5s 0.3s;
    }
    .cd-hero-slider li.selected .cd-btn {
        -webkit-transition: opacity 0.4s 0.4s, -webkit-transform 0.5s 0.4s, background-color 0.2s 0s;
        -moz-transition: opacity 0.4s 0.4s, -moz-transform 0.5s 0.4s, background-color 0.2s 0s;
        transition: opacity 0.4s 0.4s, transform 0.5s 0.4s, background-color 0.2s 0s;
    }
}

@media only screen and (min-width: 1170px) {
    .cd-hero-slider .cd-full-width,
    .cd-hero-slider .cd-half-width {
        padding-top: 200px;
    }
    .cd-hero-slider h2,
    .cd-hero-slider p {
        margin-bottom: 20px;
    }
    .cd-hero-slider h2 {
        font-size: 3.2em;
    }
    .cd-hero-slider p {
        font-size: 1.6rem;
    }
}

.cd-hero-slider h2,
.cd-hero-slider p {
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    line-height: 1.2;
    color: #ffffff;
    width: 60%;
}

.cd-slider-nav {
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 2;
    text-align: center;
    height: 20px;
}

.cd-slider-nav nav,
.cd-slider-nav ul,
.cd-slider-nav li,
.cd-slider-nav a {
    height: 100%;
}

.cd-slider-nav nav {
    display: inline-block;
    position: relative;
}

.cd-slider-nav ul::after {
    clear: both;
    content: "";
    display: table;
}

.cd-slider-nav li {
    display: inline-block;
    width: 20px;
}

.cd-slider-nav li.selected a {
    color: #2c343b;
}

.no-touch .cd-slider-nav li.selected a:hover {
    background-color: transparent;
}

.cd-slider-nav a {
    display: block;
    position: relative;
    padding-top: 35px;
    font-size: 1rem;
    font-weight: 700;
    color: #a8b4be;
    -webkit-transition: background-color 0.2s;
    -moz-transition: background-color 0.2s;
    transition: background-color 0.2s;
}

.cd-slider-nav a::before {
    border: 1px solid #fff;
    border-radius: 500px;
    content: "";
    height: 15px;
    left: 50%;
    position: absolute;
    right: auto;
    top: -39px;
    transform: translateX(-50%);
    width: 15px;
}

.no-touch .cd-slider-nav a:hover {
    background-color: rgba(0, 1, 1, 0.5);
}

.no-js .cd-hero-slider li {
    display: none;
}

.no-js .cd-hero-slider li.selected {
    display: block;
}

.no-js .cd-slider-nav {
    display: none;
}

// Slick Slider 
.slider-img-brdr {
    width: 120% !important;
    left: 0% !important;
    top: 0 !important;
    position: relative !important;
}

.slider { 
    display: none; 

    .slick-slide {
        
        @media (min-width: 768px) {
            
        }
    }
}
.slider.slick-initialized { 
    height: auto;
    display: block; 
}

.slick-slide {
    img {
        display: inline-block;
    }
    h2 {
        text-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        line-height: 1.2;
        color: #ffffff;
        margin-bottom: 5px;
        font-size: 2em;
        
        @media (min-width: 1024px) {
            margin-bottom: 10px;
            font-size: 2.5em;
        }
        @media (min-width: 1440px) {
            font-size: 3.2em;
        }
    }
}

.slick-track {
    height: 100vh;
    @media (min-width: 768px) {
        margin-top: 0;
    }
}

.slick-dots {
    bottom: 10%;
    width: 100%;
    margin: 0;
    list-style: none;
    text-align: center;
    z-index: 3;
    color: #EC2028;
    margin-top: -17%;

    @media (min-width: 768px) {
        bottom: 10%;
    }
}

.slick-dots li.slick-active button:before {
    opacity: .75;
    color: #EC2028;
}

.slick-dots li button:before {
    font-size: 15px;
    border-style: solid;
    border-radius: 50%;
    color: transparent;
    line-height: 9;
    border: solid 1.5px #fff;
    position: absolute;
    line-height: 19px;
    width: 20px;
    height: 20px;
    padding: 0px;
    margin-right: auto;
    margin-left: auto;
}

.slick-slide:focus {
    outline: none;
}


.slick-slide .cd-btn {
    color: #ffffff;
    border: 1px solid #ffffff;
    background-color: transparent;
    font-family: "Nexa-Light", sans-serif;
    padding: 10px 20px 6px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 18px;
    font-size: 1.8rem;
    margin: 0;
}

.slick-slide .cd-btn:hover {
    border: 1px solid #ffffff;
    text-decoration: none;
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.2);
    -webkit-transition: all 200ms ease-out 0.2s;
    -moz-transition: all 200ms ease-out 0.2s;
    -o-transition: all 200ms ease-out 0.2s;
    transition: all 200ms ease-out 0.2s;
}

.slick-slide .cd-btn.secondary {
    background-color: rgba(22, 26, 30, 0.8);
}

.slick-slide .cd-btn {
    font-size: 1.4rem;
}

.slick-list {
    margin-top: 0;
    

    @media (min-width: 768px) {
        margin-top: 0;
    }
}

.career-inner .slick-list {
    margin-top: 10%;
}

#career-slider .slick-list {
    margin-top: 0;
}

#career-slider .slick-track {
    height: auto;
}

.slick-next,
.slick-prev {
    width: 20px;
    height: auto;
}

.slick-next:before,
[dir=rtl] .slick-prev:before {
    content: '>';
}

.slick-next:before,
.slick-prev:before {
    font-size: 97px;
    font-family: monospace;
}

.slick-prev:before {
    content: '<';
}

.slick-prev {
    left: -5%;
}

.slick-initialized .slick-slide {
    display: block;
    padding-right: 0px;
}

.banner-container.slick-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: cover !important; 
    background-position: center 70px !important;

    @media(min-width: 768px) {
        background-position: center center !important;
    }

    @media(min-width: 992px) {
        flex-direction: row !important;
    }
}

.slick-dotted.slick-slider {
    margin-bottom: 0;
}