.news-item {
    h2 {
        text-align: left;
        margin-bottom: 10px;
        font-weight: 900;
    }
}

.info {
    padding: 0;
}

.read-article {
    font-size: 16px;
    font-weight: 100;
    line-height: 40px;
    margin: 1em 0 1em;
    width: 100%;
    padding: 0;
}



.news-item {
    background: transparent;
}

.article-container {
    background: transparent;
    text-align: center;
}

.featured-image-container {
    width: 100%;
    margin: auto;
    text-align: center;
}

.feature-image-container {
    padding: 10px;

    img {
        margin-left: auto;
        margin-right: auto;
    }
}

.featured-image {
    margin: auto;
    width: 70%;
}

.news-article-header {
    padding: 20px 0px;
    
    h2 {
        font-weight: 900;
        width: 70%;
        margin: 0 auto;
    }
    .author {
        font-size: 2rem;
        font-weight: 100;
        text-transform: uppercase;
    }
}

.article-intro {
    width: 85%;
    margin-left: auto;
    margin-right: auto;    
    text-align: left;
}

.article-content {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
}

.pagination>li>a, .pagination>li>span {
    position: relative;
    float: left;
    padding: 6px 12px;
    line-height: 1.42857143;
    color: #337ab7;
    text-decoration: none;
    border: 1px solid;
    color: #fff;
    cursor: default;
    background-color: transparent;
    border-color: rgba(0, 102, 147, 0.9);
    border-radius: 10px;
    margin-left: 5px;
    margin-right: 5px;
}

.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
    z-index: 3;
    color: #fff;
    cursor: default;
    background-color: rgba(255, 255, 255, 0.2);
    border-color: rgba(0, 102, 147, 0.9);
    border-radius: 10px;
    margin-left: 5px;
    margin-right: 5px;
}

.pagination>li>a:focus, .pagination>li>a:hover, .pagination>li>span:focus, .pagination>li>span:hover {
    z-index: 2;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.2);
    border-color: rgba(0, 102, 147, 0.9);
    cursor: pointer;
}

.pagination>li:first-child>a, .pagination>li:first-child>span {
    margin-left: 5px;
    border-radius: 10px;
}

.pagination>li:last-child>a, .pagination>li:last-child>span {
    border-radius: 10px;
}

.back-button.container {
    float: right;
    margin-right: 5%;
}

.jsBack .fa {
    background: transparent;
    color: #fff;
    font-size: 1.5em;
}